import { styled } from '@mui/material/styles';
import { Chip as MuiChip } from '@mui/material';

export const Chip = styled(MuiChip)(
  ({ theme }) => `
  background: none;
  color: ${theme.palette.text.primary};
  border: 1px solid ${theme.palette.grey.A400};
  border-radius: 25px;
  white-space: nowrap;
  font-size: 14px;
  padding: 0 12px;

  &:hover,
  &[data-active='true'] {
    background: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
    color: white;
  }
  ${theme.breakpoints.up('sm')} {
    padding: 20px 24px;
    font-size: 16px;
  }
`
);
