import { useEffect, useState } from 'react';
import { useSurveyContext } from '@/providers/SurveyProvider';
import { TextInput } from '../../../stories/components/Input';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { css } from '@emotion/react';
import { Chip } from '../../../stories/components/AutocompleteChip';
import { Button } from '../../../stories/components/Button';
import { AddJobScreenProps } from './types';

export default function AddJobScreen({ form, getJobTitleOptions, options }: AddJobScreenProps) {
  const [showAllOptions, setShowAllOptions] = useState(false);
  const { updateHeader } = useSurveyContext();
  const { control, resetField, setValue, watch } = form;
  const watchJobTitle = watch('jobTitle') || '';
  const isNewJobTitle = !options.includes(watchJobTitle);
  const optionsSampleSize = 4;
  const isLoadMoreButtonVisible = !showAllOptions && options.length > optionsSampleSize;

  useEffect(() => {
    updateHeader({
      description:
        "We're having some trouble matching up this job title with our records. Select the closest match from the available job titles below or select “Other” to add a new job title.",
    });
  }, []);

  const selectOption = (option: string) => () => {
    if (option === 'Other') {
      resetField('jobTitle');
      resetField('confirmJobTitle');
    } else {
      setValue('jobTitle', option);
    }
  };

  const refreshOptions = async () => await getJobTitleOptions(watchJobTitle);

  // show 4 options by default up to a max of 30
  const visibleOptions = showAllOptions ? options.slice(0, 30) : options.slice(0, optionsSampleSize);

  return (
    <>
      <TextInput
        name="jobTitle"
        control={control}
        label={'Job Title'}
        required
        onChange={() => {
          resetField('confirmJobTitle');
        }}
        customRules={{
          validate: (value: string) => (value.trim() === '' ? 'Invalid Job Title' : true),
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Refresh Options">
                <IconButton aria-label="refresh job title options" onClick={refreshOptions} size="small">
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />

      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
          gap: 12px;
        `}
      >
        {visibleOptions.map((o) => (
          <Chip key={o} onClick={selectOption(o)} active={watchJobTitle === o} label={o} />
        ))}
        <Chip onClick={selectOption('Other')} active={isNewJobTitle} label="Other" />
      </div>

      {isLoadMoreButtonVisible && (
        <Button
          sx={{ alignSelf: 'flex-start', marginTop: '-20px' }}
          variant="text"
          onClick={() => setShowAllOptions(true)}
        >
          Load More
        </Button>
      )}

      {isNewJobTitle && (
        <TextInput
          name="confirmJobTitle"
          control={control}
          label={'Confirm Job Title'}
          required
          customRules={{
            validate: (value) => value === watchJobTitle || 'Job Titles do not match',
          }}
          defaultValue=""
          shouldUnregister
        />
      )}
    </>
  );
}
