import { Controller, FieldValues } from 'react-hook-form';
import { FormHelperText, Typography, useMediaQuery } from '@mui/material';
import { InputProps } from './types';
import { css, useTheme } from '@mui/material/styles';

const LikerScaleInput = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  required = false,
}: InputProps<TFieldValues>) => {
  const theme = useTheme();
  const singleLineBreakpoint = 950;
  const isSingleLineDisplay = useMediaQuery(theme.breakpoints.up(singleLineBreakpoint));

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: { value: required, message: 'Required' } }}
      render={({ field, fieldState }) => (
        <div
          css={css`
            display: grid;
            gap: 12px;
          `}
        >
          <Typography variant="body1">
            {label}
            {required ? <>&nbsp;*</> : null}
          </Typography>
          <div
            css={css`
              display: flex;
              justify-content: space-between;

              ${theme.breakpoints.up(singleLineBreakpoint)} {
                justify-content: normal;
                gap: 60px;
              }
            `}
          >
            {isSingleLineDisplay && (
              <Typography css={{ color: '#666' }} variant="body1">
                Strongly&nbsp;Disagree
              </Typography>
            )}
            <input
              type="radio"
              {...field}
              value="1 - Strongly Disagree"
              aria-label="1 - Strongly Disagree"
              checked={field.value === '1 - Strongly Disagree'}
            />
            <input type="radio" {...field} value="2" checked={field.value === '2'} aria-label="1 - Disagree" />
            <input
              type="radio"
              {...field}
              value="3"
              checked={field.value === '3'}
              aria-label="1 - Neither agree nor disagree"
            />
            <input type="radio" {...field} value="4" checked={field.value === '4'} aria-label="1 - Agree" />
            <input
              type="radio"
              {...field}
              value="5 - Strongly Agree"
              checked={field.value === '5 - Strongly Agree'}
              aria-label="1 - Strongly Agree"
            />
            {isSingleLineDisplay && (
              <Typography css={{ color: '#666' }} variant="body1">
                Strongly&nbsp;Agree
              </Typography>
            )}
          </div>
          {!isSingleLineDisplay && (
            <div css={{ display: 'flex', justifyContent: 'space-between', color: '#666' }}>
              <Typography variant="body1">Strongly&nbsp;Disagree</Typography>
              <Typography variant="body1">Strongly&nbsp;Agree</Typography>
            </div>
          )}
          {fieldState.error && (
            <FormHelperText error sx={{ margin: '4px 14px 0' }}>
              {fieldState.error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
};

export default LikerScaleInput;

export type LikertAnswer = '1 - Strongly Disagree' | '2' | '3' | '4' | '5 - Strongly Agree';
