import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { DropdownInputProps } from './types';
import { generateRules } from './generateRules';

const DropdownInput = <TFieldValues extends FieldValues>({
  control,
  name,
  required,
  customRules,
  label,
  options,
  defaultValue,
  size = 'small',
  shouldUnregister,
}: DropdownInputProps<TFieldValues>) => {
  const labelId = `${name}-label`;
  const labelText = `${label}${required ? ' *' : ''}`;

  return (
    <Controller
      control={control}
      name={name}
      rules={generateRules({ required }, customRules)}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error} size={size}>
          <InputLabel id={labelId}>{labelText}</InputLabel>
          <Select {...field} label={labelText}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {fieldState.error && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default DropdownInput;
