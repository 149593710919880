import { useEffect, useState } from 'react';
import { useSurveyContext } from '@/providers/SurveyProvider';
import { useForm } from 'react-hook-form';
import NumberInput from '@/stories/components/Input/NumberInput';
import {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_NON_BINARY,
  PREFER_NOT_TO_ANSWER,
  PREFER_TO_SELF_IDENTIFY,
} from '@/constants/options';
import VerticalCheckboxGroupInput from '@/stories/components/Input/CheckboxInput';
import { DropdownInput, TextInput } from '@/stories/components/Input';
import Form from '@/components/Survey/Form';
import { isNullOrEmpty } from '@/util/isNullOrEmpty';
import { Question } from '@/abstractions/question';

function DemographicsStep() {
  const { formData } = useSurveyContext();

  const { control, handleSubmit, setValue, watch } = useForm<DemographicsFormData>({
    defaultValues: formData.demographics,
  });

  const { submitStep, trackVirtualPageView, updateHeader } = useSurveyContext();
  const [preferNotToAnswer, setPreferNotToAnswer] = useState(false);
  const watchGender = watch('gender') || '';

  const onSubmit = handleSubmit((demographicsData) => {
    submitStep({
      completeStep: true,
      markComplete: true,
      rawFormData: demographicsData,
      stepData: mapFormData(demographicsData),
    });
  });

  useEffect(() => {
    updateHeader({
      additionalInfo:
        'Help Payscale continue the conversation on fair pay by enriching our research and data. \n\nProviding these answers will not impact your personalized pay report but will contribute to important research, like pay equity analysis.',
      title: 'Demographics',
      description: 'Tell us about your demographic attributes.',
    });

    trackVirtualPageView('/demographics');
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <NumberInput name="age" control={control} label={'Age'} min={1} max={200} />
      <DropdownInput
        name="gender"
        control={control}
        label="Gender"
        options={[GENDER_MALE, GENDER_FEMALE, GENDER_NON_BINARY, PREFER_TO_SELF_IDENTIFY].map((label: string) => ({
          label,
          value: label,
        }))}
      />
      {watchGender === PREFER_TO_SELF_IDENTIFY && (
        <TextInput
          name="genderSelfIdentification"
          control={control}
          label={'Specify Gender Identity'}
          shouldUnregister
          defaultValue=""
        />
      )}
      <VerticalCheckboxGroupInput
        name="raceEthnicity"
        control={control}
        defaultValue={[]}
        label="Race/Ethnicity"
        options={[
          'American Indian and Alaska Native',
          'Asian',
          'Black or African American',
          'Hispanic',
          'Native Hawaiian or Other Pacific Islander',
          'White',
          PREFER_NOT_TO_ANSWER,
          'Other',
        ].map((label) => ({ label }))}
        onChange={(raceEthnicity: string[]) => {
          if (raceEthnicity.length > 1 && raceEthnicity.includes(PREFER_NOT_TO_ANSWER)) {
            if (preferNotToAnswer) {
              setValue(
                'raceEthnicity',
                raceEthnicity.filter((x) => x !== PREFER_NOT_TO_ANSWER)
              );

              setPreferNotToAnswer(false);
            } else {
              setValue('raceEthnicity', [PREFER_NOT_TO_ANSWER]);
              setPreferNotToAnswer(true);
            }
          }
        }}
      />
    </Form>
  );

  function mapFormData(data: DemographicsFormData): Question[] {
    return [
      {
        questionId: 'Age',
        values: [data.age],
        skipped: !data.age,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'Gender',
        values: [data.gender],
        skipped: !data.gender,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'GenderIdentity',
        values: [data.genderSelfIdentification],
        skipped: isNullOrEmpty(data.genderSelfIdentification),
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'Race',
        values: data.raceEthnicity,
        skipped: !data.raceEthnicity?.length,
        properties: { isDefaultAnswer: false },
      },
    ];
  }
}

export interface DemographicsFormData {
  age?: number;
  gender?: string;
  genderSelfIdentification?: string;
  raceEthnicity?: string[];
}

export default DemographicsStep;
