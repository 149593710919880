import { css } from '@mui/material/styles';
import pageGradient from '@/assets/images/page-gradient.png';
import trafficCone from '@/assets/images/traffic-cone.png';
import { Link, Typography } from '@mui/material';

export default function MaintenancePage() {
  return (
    <div
      css={css`
        height: 100vh;
        background: url(${pageGradient});
        background-size: cover;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px 0 10px;

          .navbar-logo {
            height: 32px;
          }
        `}
      >
        <a href="/">
          <img
            className="navbar-logo"
            src={'//cdn-payscale.com/content/assets/payscale-logo-color.svg'}
            alt="Payscale logo"
          />
        </a>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10%;
            padding: 0 20px;
          `}
        >
          <img
            css={css`
              width: 125px;
              height: 125px;
              flex-shrink: 0;
            `}
            src={trafficCone}
            alt=""
          />

          <Typography
            component="h1"
            sx={{ fontSize: { xs: '36px', sm: '54px' }, margin: { xs: '20px 0', md: '40px 0' } }}
            color="text.primary"
          >
            Come back soon...
          </Typography>

          <div
            css={css`
              max-width: 709px;
            `}
          >
            <Typography component="p" color="text.primary" sx={{ marginBottom: '40px' }}>
              Unfortunately, our survey is down for a bit of maintenance right now but our team is working hard to get
              it up and running again soon.
            </Typography>
            <Typography component="p" color="text.primary">
              In the meantime, please check out our{' '}
              <Link href="/research/US/Country=United_States/Salary" underline="none">
                Salary Research Center
              </Link>{' '}
              to uncover detailed salary data for specific jobs, employers, schools, and more.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
