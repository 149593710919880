export const CURRENCY_TYPES = [
  { label: 'United States Dollar', value: 'USD' },
  { label: 'European Euro', value: 'EUR' },
  { label: 'Japanese Yen', value: 'JPY' },
  { label: 'British Pound Sterling', value: 'GBP' },
  { label: 'Australian Dollar', value: 'AUD' },
  { label: 'Canadian Dollar', value: 'CAD' },
  { label: 'Swiss Franc', value: 'CHF' },
  { label: 'Chinese Yuan', value: 'CNY' },
  { label: 'Swedish Krona', value: 'SEK' },
  { label: 'New Zealand Dollar', value: 'NZD' },
  { label: 'Mexican Peso', value: 'MXN' },
  { label: 'Singapore Dollar', value: 'SGD' },
  { label: 'Hong Kong Dollar', value: 'HKD' },
  { label: 'Norwegian Krone', value: 'NOK' },
  { label: 'South Korean Won', value: 'KRW' },
  { label: 'Turkish Lira', value: 'TRY' },
  { label: 'Russian Ruble', value: 'RUB' },
  { label: 'Indian Rupee', value: 'INR' },
  { label: 'Brazilian Real', value: 'BRL' },
  { label: 'South African Rand', value: 'ZAR' },
  { label: 'Indonesian Rupiah', value: 'IDR' },
  { label: 'Malaysian Ringgit', value: 'MYR' },
  { label: 'Philippine Peso', value: 'PHP' },

  { label: 'Afghanistan Afghani', value: 'AFA' },
  { label: 'Albanian Lek', value: 'ALL' },
  { label: 'Algerian Dinar', value: 'DZD' },
  { label: 'Angolan New Kwanza', value: 'AOA' },
  { label: 'Argentine Peso', value: 'ARS' },
  { label: 'Armenian Dram', value: 'AMD' },
  { label: 'Aruba Florin', value: 'AWG' },
  { label: 'Azerbaijani Manat', value: 'AZM' },
  { label: 'Bahamian Dollar', value: 'BSD' },
  { label: 'Bahraini Dinar', value: 'BHD' },
  { label: 'Bangladesh Taka', value: 'BDT' },
  { label: 'Barbados Dollar', value: 'BBD' },
  { label: 'Belarus Ruble', value: 'BYR' },
  { label: 'Belize Dollar', value: 'BZD' },
  { label: 'Bermuda Dollar', value: 'BMD' },
  { label: 'Bhutan Ngultrum', value: 'BTN' },
  { label: 'Bolivian Boliviano', value: 'BOB' },
  { label: 'Bosnian Marka', value: 'BAM' },
  { label: 'Botswana Pula', value: 'BWP' },
  { label: 'Brunei Dollar', value: 'BND' },
  { label: 'Bulgarian Lev', value: 'BGN' },
  { label: 'Burundi Franc', value: 'BIF' },
  { label: 'Cambodia Riel', value: 'KHR' },
  { label: 'Cape Verde Escudo', value: 'CVE' },
  { label: 'Cayman Islands Dollar', value: 'KYD' },
  { label: 'CFA Franc (BCEAO)', value: 'XOF' },
  { label: 'CFA Franc (BEAC)', value: 'XAF' },
  { label: 'Chilean Peso', value: 'CLP' },
  { label: 'Colombian Peso', value: 'COP' },
  { label: 'Comoros Franc', value: 'KMF' },
  { label: 'Congolese Franc', value: 'CDF' },
  { label: 'Costa Rica Colon', value: 'CRC' },
  { label: 'Croatian Kuna', value: 'HRK' },
  { label: 'Cuban Peso', value: 'CUP' },
  { label: 'Czech Koruna', value: 'CZK' },
  { label: 'Danish Krone', value: 'DKK' },
  { label: 'Dijibouti Franc', value: 'DJF' },
  { label: 'Dominican Peso', value: 'DOP' },
  { label: 'East Caribbean Dollar', value: 'XCD' },
  { label: 'Egyptian Pound', value: 'EGP' },
  { label: 'El Salvador Colon', value: 'SVC' },
  { label: 'Eritrea Nakfa', value: 'ERN' },
  { label: 'Ethiopian Birr', value: 'ETB' },
  { label: 'Falkland Islands Pound', value: 'FKP' },
  { label: 'Fiji Dollar', value: 'FJD' },
  { label: 'Gambian Dalasi', value: 'GMD' },
  { label: 'Georgian Lari', value: 'GEL' },
  { label: 'Ghanian Cedi', value: 'GHC' },
  { label: 'Gibraltar Pound', value: 'GIP' },
  { label: 'Gold Ounces', value: 'XAU' },
  { label: 'Guatemala Quetzal', value: 'GTQ' },
  { label: 'Guernsey Pound', value: 'GGP' },
  { label: 'Guinea Franc', value: 'GNF' },
  { label: 'Guyana Dollar', value: 'GYD' },
  { label: 'Haiti Gourde', value: 'HTG' },
  { label: 'Honduras Lempira', value: 'HNL' },
  { label: 'Hungarian Forint', value: 'HUF' },
  { label: 'Iceland Krona', value: 'ISK' },
  { label: 'Iran Rial', value: 'IRR' },
  { label: 'Iraqi Dinar', value: 'IQD' },
  { label: 'Isle of Man Pound', value: 'IMP' },
  { label: 'Israeli Shekel', value: 'ILS' },
  { label: 'Jamaican Dollar', value: 'JMD' },
  { label: 'Jerseyan Pound', value: 'JEP' },
  { label: 'Jordanian Dinar', value: 'JOD' },
  { label: 'Kazakhstan Tenge', value: 'KZT' },
  { label: 'Kenyan Shilling', value: 'KES' },
  { label: 'Kuwaiti Dinar', value: 'KWD' },
  { label: 'Kyrgyzstan Som', value: 'KGS' },
  { label: 'Lao Kip', value: 'LAK' },
  { label: 'Lebanese Pound', value: 'LBP' },
  { label: 'Lesotho Loti', value: 'LSL' },
  { label: 'Liberian Dollar', value: 'LRD' },
  { label: 'Libyan Dinar', value: 'LYD' },
  { label: 'Macau Pataca', value: 'MOP' },
  { label: 'Macedonian Denar', value: 'MKD' },
  { label: 'Malagasy Franc', value: 'MGF' },
  { label: 'Malawi Kwacha', value: 'MWK' },
  { label: 'Maldives Rufiyaa', value: 'MVR' },
  { label: 'Mauritania Ougulya', value: 'MRO' },
  { label: 'Mauritius Rupee', value: 'MUR' },
  { label: 'Moldovan Leu', value: 'MDL' },
  { label: 'Mongolian Tugrik', value: 'MNT' },
  { label: 'Moroccan Dirham', value: 'MAD' },
  { label: 'Mozambique Metical', value: 'MZM' },
  { label: 'Myanmar Kyat', value: 'MMK' },
  { label: 'Namibian Dollar', value: 'NAD' },
  { label: 'Nepalese Rupee', value: 'NPR' },
  { label: 'Neth Antilles Guilder', value: 'ANG' },
  { label: 'Nicaragua Cordoba', value: 'NIO' },
  { label: 'Nigerian Naira', value: 'NGN' },
  { label: 'North Korean Won', value: 'KPW' },
  { label: 'Omani Rial', value: 'OMR' },
  { label: 'Pacific Franc', value: 'XPF' },
  { label: 'Pakistani Rupee', value: 'PKR' },
  { label: 'Palladium Ounces', value: 'XPD' },
  { label: 'Panama Balboa', value: 'PAB' },
  { label: 'Papua New Guinea Kina', value: 'PGK' },
  { label: 'Paraguayan Guarani', value: 'PYG' },
  { label: 'Peruvian Nuevo Sol', value: 'PEN' },
  { label: 'Platinum Ounces', value: 'XPT' },
  { label: 'Polish Zloty', value: 'PLN' },
  { label: 'Qatar Rial', value: 'QAR' },
  { label: 'Romanian Leu', value: 'ROL' },
  { label: 'Rwanda Franc', value: 'RWF' },
  { label: 'Samoa Tala', value: 'WST' },
  { label: 'Sao Tome Dobra', value: 'STD' },
  { label: 'Saudi Arabian Riyal', value: 'SAR' },
  { label: 'Seychelles Rupee', value: 'SCR' },
  { label: 'Sierra Leone Leone', value: 'SLL' },
  { label: 'Silver Ounces', value: 'XAG' },
  { label: 'Solomon Islands Dollar', value: 'SBD' },
  { label: 'Somali Shilling', value: 'SOS' },
  { label: 'Sri Lanka Rupee', value: 'LKR' },
  { label: 'St Helena Pound', value: 'SHP' },
  { label: 'Sudanese Pound', value: 'SSP' },
  { label: 'Surinam Dollar', value: 'SRD' },
  { label: 'Swaziland Lilageni', value: 'SZL' },
  { label: 'Syrian Pound', value: 'SYP' },
  { label: 'Taiwan Dollar', value: 'TWD' },
  { label: 'Tanzanian Shilling', value: 'TZS' },
  { label: 'Thai Baht', value: 'THB' },
  { label: "Tonga Pa'anga", value: 'TOP' },
  { label: 'Trinidad&Tobago Dollar', value: 'TTD' },
  { label: 'Tunisian Dinar', value: 'TND' },
  { label: 'Turkmen Manat', value: 'TNM' },
  { label: 'UAE Dirham', value: 'AED' },
  { label: 'Ugandan Shilling', value: 'UGX' },
  { label: 'Ukraine Hryvnia', value: 'UAH' },
  { label: 'Uruguayan New Peso', value: 'UYU' },
  { label: 'Uzbekistani Sum', value: 'UZS' },
  { label: 'Vanuatu Vatu', value: 'VUV' },
  { label: 'Venezuelan Bolivar', value: 'VEB' },
  { label: 'Vietnam Dong', value: 'VND' },
  { label: 'Yemen Riyal', value: 'YER' },
  { label: 'Yugoslav Dinar', value: 'YUM' },
  { label: 'Zaire New Zaire', value: 'ZRN' },
  { label: 'Zambian Kwacha', value: 'ZMK' },
  { label: 'Zimbabwe Dollar', value: 'ZWD' },
];
