import {
  AutocompleteInput,
  NumberInput,
  RadioInput,
  DropdownInput,
  HorizontalToggleInput,
} from '@/stories/components/Input';
import type { JobScreenProps } from './types';
import { useCallback, useEffect } from 'react';
import { useSurveyContext } from '@/providers/SurveyProvider';
import { Divider } from '@mui/material';
import { CURRENCY_TYPES } from '@/constants/currencies';
import GridColumns from '@/components/shared/GridColumns';
import useAutocomplete from '@/hooks/useAutocomplete';
import useGeolocationEffect from '@/hooks/useGeolocationEffect';

export default function EnterJobScreen({ form, getJobTitleOptions }: JobScreenProps) {
  const { searchCountries, searchLocations } = useAutocomplete();
  const { control, setValue, watch } = form;
  const { trackVirtualPageView, updateHeader } = useSurveyContext();
  const watchJobTitle = watch('jobTitle') || '';
  const watchLocation = watch('location') || '';
  const watchCountry = watch('country') || '';
  const watchJobSituation = watch('jobSituation') || '';

  useGeolocationEffect(
    ({ location, country }: { location: string; country: string }) => {
      setValue('location', location, { shouldDirty: false });
      setValue('country', country, { shouldDirty: false });
    },
    { country: watchCountry, location: watchLocation }
  );

  useEffect(() => {
    updateHeader({
      additionalInfo:
        'Some aspects of your job (such as job title, years of experience, location, and salary) will have the biggest impact on how much you should be paid. \n\nProviding these basics will ensure we give you an accurate pay report for your role.',
      title: 'Tell us about yourself',
      description: 'To unlock personalized salary insights, share your job and salary to learn more about your worth.',
    });

    trackVirtualPageView('/your-job');
  }, []);

  // Callback changes when country changes because location autocomplete depends on country
  const getLocationOptions = useCallback(
    (searchTerm: string) => searchLocations(watchCountry, searchTerm),
    [watchCountry]
  );

  return (
    <>
      <RadioInput
        name="jobSituation"
        control={control}
        label="Which option best describes your situation?"
        required
        options={[{ label: 'Current Job' }, { label: 'Job Offer' }]}
      />
      {watchJobSituation === 'Current Job' && (
        <RadioInput
          name="soughtNewJob"
          control={control}
          label="In the next 6 months, I plan on actively seeking new jobs outside of my current company."
          options={[{ label: 'Yes' }, { label: 'No' }]}
        />
      )}
      <AutocompleteInput
        name="jobTitle"
        control={control}
        label="Job Title"
        required
        customRules={{
          validate: (value: string) => (value.trim() === '' ? 'Invalid Job Title' : true),
        }}
        currentValue={watchJobTitle}
        getOptions={getJobTitleOptions}
        allowFreeText
      />
      <NumberInput
        name="yearsOfExperience"
        control={control}
        label={'Years of Experience'}
        required
        min={0}
        max={100}
      />
      <AutocompleteInput
        name="location"
        control={control}
        label={'Location'}
        required
        currentValue={watchLocation}
        getOptions={getLocationOptions}
      />
      <AutocompleteInput
        name="country"
        control={control}
        label={'Country'}
        required
        currentValue={watchCountry}
        getOptions={searchCountries}
        onChange={() => setValue('location', '')}
      />

      <Divider />

      <HorizontalToggleInput
        name="payType"
        control={control}
        ariaLabel={'pay type'}
        options={[
          { label: 'Annual', value: 'Annual', ariaLabel: 'annual salary' },
          { label: 'Hourly', value: 'Hourly', ariaLabel: 'hourly pay' },
        ]}
      />
      <GridColumns columns={3}>
        <DropdownInput name="currency" control={control} label="Type of Currency" options={CURRENCY_TYPES} required />

        {watch('payType') === 'Annual' ? (
          <>
            <NumberInput
              name="annualBaseSalary"
              control={control}
              label="Annual Base Salary"
              min={0}
              max={5000000}
              shouldUnregister
              required
            />

            <DropdownInput
              name="salaryReportedFor"
              control={control}
              label="Salary Reported For"
              options={salaryReportedForOptions}
              defaultValue=""
              shouldUnregister
              required
            />
          </>
        ) : (
          <>
            <NumberInput
              name="baseHourlyRate"
              control={control}
              label="Base Hourly Rate"
              min={0}
              shouldUnregister
              required
            />

            <NumberInput
              name="averageRegularHoursPerWeek"
              control={control}
              label="Average Regular Hours/Week"
              min={0}
              shouldUnregister
              required
            />
          </>
        )}
      </GridColumns>
    </>
  );
}

const salaryReportedForOptions = [
  'Full-time (40+ hours/wk)',
  'Part-time (35-39 hours/wk)',
  'Part-time (30-34 hours/wk)',
  'Part-time (25-29 hours/wk)',
  'Part-time (20-24 hours/wk)',
  'Part-time (15-19 hours/wk)',
  'Teaching (9-10 month contract)',
  'Teaching (11-12 month contract)',
].map((label) => ({ label, value: label }));
