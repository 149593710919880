import { useEffect } from 'react';
import { useSurveyContext } from '@/providers/SurveyProvider';
import { Typography } from '@mui/material';
import { NavigationButton } from '@/stories/components/Button';
import { css, useTheme } from '@mui/material/styles';
import { REPORT_URL } from '@/constants/urls';
import { trackSurveyCompleted } from '@/util/dataLayer';


const CompletionStep = () => {
  const { formData, surveyId, userId, trackVirtualPageView, updateHeader } = useSurveyContext();

  useEffect(() => {
    updateHeader({
      additionalInfo: '',
      title: 'Thank you for taking our survey!',
      description: '',
    });

    trackVirtualPageView('/thank-you');
    trackSurveyCompleted(userId, surveyId, formData.job.jobSituation);    
  }, []);

  const navigateToReport = () => {
    window!.top!.location.href = `${REPORT_URL}?surveyId=${surveyId}&tab=personal`;
  };
  const theme = useTheme();
  return (
    <div
      // duplicate of styles in <Form/>
      css={css`
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 32px;

        ${theme.breakpoints.up('sm')} {
          padding: 0 60px;
        }

        ${theme.breakpoints.up('md')} {
          padding: 0 116px;
        }
      `}
    >
      <Typography>
        Your personalized pay report awaits. Compensation is always changing, so it’s important to stay informed and
        talk about your pay with your employer.
      </Typography>
      <Typography>
        Keep the conversation going, ask your organization or future employer about their compensation philosophy and
        strategy!
      </Typography>

      <NavigationButton
        direction="forward"
        css={css`
          align-self: flex-end;
          margin-top: auto;
          width: 100%;
          ${theme.breakpoints.up('sm')} {
            width: auto;
          }
        `}
        onClick={navigateToReport}
      >
        Personal Salary Report
      </NavigationButton>
    </div>
  );
};

export default CompletionStep;
