export const PAYSCALE_BASE_URL = import.meta.env.VITE_PAYSCALE_BASE_URL;

export const DETECT_GEOLOCATION_URL = `${import.meta.env.VITE_PAYSCALE_BASE_URL}/geotarget/v1/City`;

export const SURVEY_API_URL = `${import.meta.env.VITE_PAYSCALE_BASE_URL}/api/survey`;

export const REPORT_URL = `${import.meta.env.VITE_PAYSCALE_BASE_URL}/mypayscale.aspx`;

export const POPULAR_ANSWERS_API_URL = `${import.meta.env.VITE_PAYSCALE_BASE_URL}/popularanswers.aspx`;

export const CONSUMER_API_BASE_URL = `${import.meta.env.VITE_CONSUMER_API_BASE_URL}`;
