import { createTheme, ThemeOptions } from '@mui/material/styles';

const options: ThemeOptions = {
  palette: {
    primary: {
      main: '#4881e6',
      contrastText: '#fff',
    },
    text: {
      primary: '#2f4156',
    },
    grey: {
      A400: '#94949e',
    },
  },
  typography: {
    fontFamily:
      "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    fontSize: 16,
    button: {
      fontSize: '1rem',
      textTransform: 'none',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.4,
    },
  },
};

export default createTheme(options);
