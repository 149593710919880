import { ValidationRules } from '@/stories/components/Input/types';

export function generateRules(options: ValidationOptions, customRules?: ValidationRules): ValidationRules {
  const rules: ValidationRules = {};
  const { required } = options;

  if (required) {
    rules.required = { value: true, message: 'Required' };
  }

  return customRules ? { ...rules, ...customRules } : rules;
}

export interface ValidationOptions {
  required: boolean | undefined;
}
