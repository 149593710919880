import { useEffect } from 'react';
import { detectLocation } from '@/api/detectLocation';

export default function useGeolocationEffect(
  cb: ({ location, country }: { location: string; country: string }) => void,
  currentValues: { country?: string; location?: string } = {}
) {
  useEffect(() => {
    const { country = '', location = '' } = currentValues;

    // do not detect if location values present
    if (country.length || location.length) return;

    detectLocation()
      .then(({ city, state, country }) => {
        cb({ location: city && state ? `${city}, ${state}` : '', country });
      })
      .catch(() => {
        cb({ location: '', country: '' });
      });
  }, []);
}
