import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { JobFormData } from './types';
import { useSurveyContext } from '@/providers/SurveyProvider';
import EnterJobScreen from './EnterJobScreen';
import AddJobScreen from './AddJobScreen';
import Form from '@/components/Survey/Form';
import { Question } from '@/abstractions/question';
import { Skeleton } from '@mui/material';
import { isNullOrEmpty } from '@/util/isNullOrEmpty';
import { trackCompensationSubmitted } from '@/util/dataLayer';
import useAutocomplete from '@/hooks/useAutocomplete';

const JobStep = () => {
  const [activeForm, setActiveForm] = useState<'job' | 'addJob'>('job');
  const [jobTitleOptions, setJobTitleOptions] = useState<string[]>([]);
  const { userId, formData, submitStep, createSurvey, setReadyToSubmit, useAnonymousLoginEffect } = useSurveyContext();
  const { searchJobTitles } = useAutocomplete();
  const form = useForm<JobFormData>({
    defaultValues: formData.job,
  });

  const { handleSubmit, formState, watch } = form;

  const watchJobTitle = watch('jobTitle') || '';
  const jobTitleExists = jobTitleOptions.includes(watchJobTitle);

  const onSubmit = handleSubmit((jobData) => {
    const profileType = jobData.jobSituation == 'Current Job' ? '1' : '2';

    if (jobTitleExists || activeForm === 'addJob') {
      createSurvey(profileType)
        .then(({ surveyId }) => {
          submitStep({
            rawFormData: jobData,
            stepData: mapFormData(jobData),
            surveyId,
          });

          // google analytics
          trackCompensationSubmitted(userId, surveyId, formData.job.jobSituation);
        })
        .catch(console.error);
    } else {
      submitStep({
        completeStep: false,
        rawFormData: jobData,
        stepData: mapFormData(jobData),
      });
      setActiveForm('addJob');
    }
  });

  useEffect(() => {
    // isValid will be false when form is loaded - check for errors instead
    setReadyToSubmit(!Object.keys(formState.errors).length);
  }, [formState]);

  const setFormValues = () => {
    form.setValue('jobTitle', formData.job.jobTitle);
    form.setValue('yearsOfExperience', formData.job.yearsOfExperience);

    if (formData.job.location) {
      form.setValue('location', formData.job.location);
    }

    if (formData.job.country) {
      form.setValue('country', formData.job.country);
    }

    form.setValue('jobSituation', formData.job.jobSituation);
  };

  // new anonymous login flow
  const { waitingForAnonLogin } = useAnonymousLoginEffect(setFormValues);

  return (
    <Form onSubmit={onSubmit}>
      {waitingForAnonLogin ? (
        <>
          <Skeleton variant="rounded" height={40} />
          <Skeleton variant="rounded" height={40} />
          <Skeleton variant="rounded" height={40} />
          <Skeleton variant="rounded" height={40} />
          <Skeleton />
        </>
      ) : (
        <>
          {activeForm === 'job' && <EnterJobScreen form={form} getJobTitleOptions={getJobTitleOptions} />}

          {activeForm === 'addJob' && (
            <AddJobScreen form={form} options={jobTitleOptions} getJobTitleOptions={getJobTitleOptions} />
          )}
        </>
      )}
    </Form>
  );

  async function getJobTitleOptions(inputValue: string) {
    const jobTitleOptions = await searchJobTitles(inputValue);
    setJobTitleOptions(jobTitleOptions);
    return jobTitleOptions;
  }

  function mapFormData(data: JobFormData): Question[] {
    const { jobTitle, yearsOfExperience, location, country, soughtNewJob } = data;

    const mappedData = [
      {
        // Not skipped because it's required
        questionId: 'JobTitle',
        values: [jobTitle],
        skipped: false,
        properties: {
          isDefaultAnswer: false,
        },
      },
      {
        // Not skipped because it's required
        questionId: 'YearsExperience',
        values: [yearsOfExperience],
        skipped: false,
        properties: {
          isDefaultAnswer: false,
        },
      },
      {
        // Not skipped because it's required
        questionId: 'Location',
        values: [location],
        skipped: false,
        properties: {
          isDefaultAnswer: formState.dirtyFields.location,
        },
      },
      {
        // Not skipped because it's required
        questionId: 'Country',
        values: [country],
        skipped: false,
        properties: {
          isDefaultAnswer: formState.dirtyFields.country,
        },
      },
      {
        // Not skipped because it always has a value
        questionId: 'paytype',
        values: [data.payType],
        skipped: false,
        properties: { isDefaultAnswer: data.payType === 'Annual' },
      },
      {
        // Not skipped because it always has a value
        // Legacy survey never counts value as default answer
        questionId: 'currency',
        values: [data.currency],
        skipped: false,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'YearlyPay',
        values: [data.annualBaseSalary],
        skipped: !data.annualBaseSalary,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'HourlyPay',
        values: [data.baseHourlyRate],
        skipped: !data.baseHourlyRate,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'HoursPerWeek',
        values: [data.averageRegularHoursPerWeek],
        skipped: !data.averageRegularHoursPerWeek,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'SalaryHoursPerWeek',
        values: [data.salaryReportedFor],
        skipped: isNullOrEmpty(data.salaryReportedFor),
        properties: { isDefaultAnswer: false },
      },
    ];

    if (data.jobSituation === 'Current Job') {
      mappedData.push({
        questionId: 'SoughtNewJob',
        values: [soughtNewJob],
        skipped: !soughtNewJob,
        properties: { isDefaultAnswer: formState.dirtyFields.soughtNewJob },
      });
    }

    return mappedData;
  }
};

export default JobStep;
