import { Autocomplete, Box, TextField } from '@mui/material';
import { Chip } from '@/stories/components/AutocompleteChip';
import { Controller, FieldValues } from 'react-hook-form';
import { useDebounce } from '@/hooks/useDebounce';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import { Button } from '@/stories/components/Button';
import { AutocompleteChipInputProps } from '@/stories/components/Input/types';
import { generateRules } from './generateRules';

const CHIPS_VISIBLE_DEFAULT = 5;
const CHIPS_VISIBLE_LIMIT = 30;

const AutocompleteChipsInput = <TFieldValues extends FieldValues>({
  name,
  control,
  currentValue,
  customRules,
  label,
  placeholder,
  getOptions,
  getChipOptions,
  required,
  selectionLimit = Infinity,
}: AutocompleteChipInputProps<TFieldValues>) => {
  const [options, setOptions] = useState<string[]>([]);
  // chip options do not change after initial render
  const [chipOptions, setChipOptions] = useState<string[]>([]);
  const [chipsVisible, setChipsVisible] = useState(CHIPS_VISIBLE_DEFAULT);
  const [inputValue, setInputValue] = useState<string>('');

  const updateOptions = async (inputValue: string) => setOptions(await getOptions(inputValue));
  const updateOptionsDebounce = useDebounce(updateOptions, 300, []);

  const visibleChips = chipOptions
    // don't show options that are already selected
    .filter((option) => !currentValue.includes(option))
    // limit visible options
    .slice(0, chipsVisible);

  // show load more button only when there are more hidden chips and the limit is not reached
  const showLoadMore =
    chipsVisible <
    Math.min(
      CHIPS_VISIBLE_LIMIT,
      // do not count selected chips - they are shown in the input
      chipOptions.filter((option) => !currentValue.includes(option)).length
    );

  const atSelectionLimit = currentValue.length >= selectionLimit;

  useEffect(() => {
    if (getChipOptions) {
      getChipOptions()
        .then((options) => {
          setOptions(options);
          setChipOptions(options);
        })
        .catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (!inputValue) return;
    updateOptionsDebounce(inputValue)?.catch(console.error);
  }, [inputValue]);

  return (
    <Controller
      name={name}
      control={control}
      rules={generateRules({ required }, customRules)}
      render={({ field }) => (
        <>
          <Autocomplete
            multiple
            freeSolo={!atSelectionLimit}
            options={options}
            inputValue={inputValue}
            onInputChange={(_, value) => {
              // prevent user typing when the limit is reached
              if (atSelectionLimit) return;
              setInputValue(value);
            }}
            onChange={(e, value) => {
              // prevent dropdown options from being added when the limit is reached
              if (value.length <= selectionLimit) {
                field.onChange(value);
              }
            }}
            value={currentValue}
            renderTags={(value: readonly string[], getTagProps: AutocompleteRenderGetTagProps) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '12px',
                  maxWidth: '100%',
                }}
              >
                {value.map((option: string, index: number) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return <Chip active key={key} {...tagProps} label={option} />;
                })}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={atSelectionLimit ? '' : placeholder}
                InputLabelProps={{ shrink: true }}
                disabled={atSelectionLimit}
                css={css`
                  .MuiInputBase-root {
                    padding: 10px 14px 8px;
                  }
                `}
              />
            )}
          />

          {visibleChips?.length ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
              {visibleChips.map((value) => (
                <Chip
                  key={value}
                  disabled={currentValue.length >= selectionLimit}
                  onClick={() => field.onChange([...currentValue, value])}
                  label={value}
                />
              ))}
            </Box>
          ) : null}

          {showLoadMore && (
            <Button
              sx={{ alignSelf: 'flex-start', marginTop: '-20px' }}
              variant="text"
              onClick={() => setChipsVisible(CHIPS_VISIBLE_LIMIT)}
            >
              Load More
            </Button>
          )}
        </>
      )}
    />
  );
};

export default AutocompleteChipsInput;
