import JobStep from '@/components/steps/JobStep';
import PayStep from '@/components/steps/PayStep';
import SkillsStep from '@/components/steps/SkillsStep';
import EducationStep from '@/components/steps/EducationStep';
import EmployerStep from '@/components/steps/EmployerStep';
import DemographicsStep from '@/components/steps/DemographicsStep';
import { DEMOGRAPHICS_STEP, EDUCATION_STEP, EMPLOYER_STEP, JOB_STEP, PAY_STEP, SKILLS_STEP } from '@/constants/steps';

const SurveyStep = ({ id }: { id: string }) => {
  switch (id) {
    case JOB_STEP:
      return <JobStep />;

    case PAY_STEP:
      return <PayStep />;

    case SKILLS_STEP:
      return <SkillsStep />;

    case EDUCATION_STEP:
      return <EducationStep />;

    case EMPLOYER_STEP:
      return <EmployerStep />;

    case DEMOGRAPHICS_STEP:
      return <DemographicsStep />;

    default:
      throw new Error(`Invalid survey step: ${id}`);
  }
};

export default SurveyStep;
