import { Box, Divider, Typography } from '@mui/material';
import { useSurveyContext } from '@/providers/SurveyProvider';
import HelpIcon from '@mui/icons-material/Help';
import { LightTooltip } from '@/stories/components/Core/LightTooltip';
import { css } from '@mui/material/styles';

export default function Header() {
  const {
    header: { additionalInfo, description, title },
  } = useSurveyContext();

  return (
    <Box
      component="header"
      sx={{ padding: { xs: '0 20px', sm: '0 60px', md: '0 116px' } }}
      css={css`
        margin-bottom: 20px;
      `}
    >
      <Typography component="h2" sx={{ fontSize: '1.5rem', fontWeight: 700, margin: '0 0 4px' }} color="textPrimary">
        {title}
      </Typography>
      {description?.length ? (
        <span>
          <Typography component={'span'} sx={{ margin: 0 }} color="textPrimary">
            {description}
          </Typography>
          {additionalInfo?.length ? (
            <LightTooltip title={additionalInfo}>
              <HelpIcon
                color="primary"
                sx={{
                  fontSize: 17,
                  marginLeft: '5px',
                  verticalAlign: 'text-bottom',
                }}
              />
            </LightTooltip>
          ) : null}
        </span>
      ) : null}

      <Divider css={css`margin-top: 20px;`} />
    </Box>
  );
}
