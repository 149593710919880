import axios from 'axios';
import { CONSUMER_API_BASE_URL } from '@/constants/urls';
import { validateStatusCode } from '@/util/validateStatusCode';
import { z } from 'zod';

const createAnonymousLoginResponseSchema = z.number();
export type CreateAnonymousLoginNumber = z.infer<typeof createAnonymousLoginResponseSchema>;

export async function createAnonymousLogin() {
  const response = await axios.get<CreateAnonymousLoginNumber>(`${CONSUMER_API_BASE_URL}/api/auth/anonymous`, {
    params: {},
    withCredentials: true,
  });

  validateStatusCode(response);

  const validatedData = createAnonymousLoginResponseSchema.safeParse(response.data);

  if (!validatedData.success) {
    console.error('Error validating create anonymous login response', JSON.stringify(validatedData.error.format()));
    throw new Error();
  }

  return validatedData.data;
}
