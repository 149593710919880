import { Controller, FieldValues } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ToggleInputProps, ToggleOption } from '@/stories/components/Input/types';

const HorizontalToggleInput = <TFieldValues extends FieldValues>({
  control,
  name,
  options,
  ariaLabel,
}: ToggleInputProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <ToggleButtonGroup
          {...field}
          exclusive
          aria-label={ariaLabel}
          sx={{
            height: 40,
            borderRadius: 25,
            alignSelf: 'flex-start',
            border: '1px solid',
            borderColor: 'grey.A400',
            '& .MuiToggleButton-root': {
              border: 'none',
              padding: '0 24px',
              ':hover': {
                backgroundColor: 'inherit',
              },
            },
            '& .MuiToggleButton-root.Mui-selected': {
              // ensure we cover border of ToggleButtonGroup
              margin: '-1px',
              borderRadius: '25px',
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              ':hover': {
                backgroundColor: 'primary.dark',
              },
            },
          }}
        >
          {options.map(({ label, value, ariaLabel }: ToggleOption) => (
            <ToggleButton key={value} value={value} aria-label={ariaLabel} sx={{ borderRadius: 25 }}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    />
  );
};

export default HorizontalToggleInput;
