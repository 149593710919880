import { css, useTheme } from '@mui/material/styles';
import bannerGradient from '@/assets/images/banner-gradient.png';
import { Typography } from '@mui/material';

export default function BannerHeader() {
  const theme = useTheme();

  return (
    <div
      css={css`
        padding: 16px 20px;
        background: url(${bannerGradient});
        background-size: cover;

        ${theme.breakpoints.up('sm')} {
          padding: 24px 60px;
        }

        ${theme.breakpoints.up('md')} {
          padding: 24px 116px;
        }
      `}
    >
      <Typography
        component="h1"
        sx={{
          fontSize: { xs: '1.125rem', md: '2.25rem' },
          fontWeight: 500,
          marginBottom: 1,
        }}
        color="textPrimary"
      >
        What should I be paid?
      </Typography>
      <Typography color="textPrimary" sx={{ marginBottom: 1.5, maxWidth: '750px', opacity: 0.8 }}>
        Empower yourself with salary data that is tailored to your career and qualifications. Take our salary survey to
        receive a personalized pay report and contribute to important research.
      </Typography>
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            width: 4px;
            background-color: ${theme.palette.text.primary};
            opacity: 0.8;
            border-radius: 10px;
            margin-right: 12px;
          `}
        ></div>
        <div>
          <Typography color="textPrimary" sx={{ fontSize: '0.75rem', fontWeight: 700 }}>
            Remember
          </Typography>
          <Typography color="textPrimary" sx={{ fontSize: '0.75rem', maxWidth: '750px', opacity: 0.8 }}>
            As we lead you through this survey, the more specific your answers are the more accurate your salary report
            will be. And, any information you share with us will be kept confidential.
          </Typography>
        </div>
      </div>
    </div>
  );
}
