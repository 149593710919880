import axios from 'axios';
import { SURVEY_API_URL } from '@/constants/urls';
import { Question } from '@/abstractions/question';
import { validateStatusCode } from '@/util/validateStatusCode';
import { z } from 'zod';

const saveAnswersResponseSchema = z.object({
  affinities: z.array(z.string()),
  consistencyToken: z.string()
})

export type SaveAnswersResponse = z.infer<typeof saveAnswersResponseSchema>;

export async function saveAnswers(surveyId: string, questions: Question[]) {
  for (const question of questions) {
    question.values = question.values?.filter((x) => !!x).map((x) => `${x}`) ?? [];
  }

  const response = await axios.post<SaveAnswersResponse>(`${SURVEY_API_URL}/${surveyId}/answers`, questions, {
    withCredentials: true,
  });

  validateStatusCode(response);

  const validatedData = saveAnswersResponseSchema.safeParse(response.data);

  if (!validatedData.success) {
    console.error('Error validating save answers response', JSON.stringify(validatedData.error.format()));
    throw new Error();
  }

  return validatedData.data;
}