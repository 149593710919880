import { FC, ReactNode, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { HAS_COMPLETED_SIMPLE_SURVEY_COOKIE, SESSION_COOKIE } from '@/constants/cookies';
import { SurveyStepOptions } from '@/abstractions/surveyStepOptions';
import { SURVEY_STEPS } from '@/constants/surveySteps';
import { createGenericContext } from './createGenericContext';
import { SurveyApi } from '@/api/survey';
import { PAYSCALE_BASE_URL } from '@/constants/urls';
import { JobFormData } from '@/components/steps/JobStep/types';
import { PayFormData } from '@/components/steps/PayStep';
import { EducationFormData } from '@/components/steps/EducationStep';
import { EmployerFormData } from '@/components/steps/EmployerStep';
import { SkillsFormData } from '@/components/steps/SkillsStep';
import { DemographicsFormData } from '@/components/steps/DemographicsStep';
import { isNumeric } from '@/util/isNumeric';
import { trackGoogleAnalyticsPageView } from '@/util/dataLayer';
import { createAnonymousLogin } from '@/api/createAnonymousLogin';
import { getGaClientId } from '@/util/getGaClientId';
import { getVisitorId } from '@/util/optimizely';
import { datadogLogs } from '@datadog/browser-logs';

export interface Header {
  additionalInfo: string;
  title: string;
  description: string;
}

export interface SubmitStepOptions {
  completeStep?: boolean;
  markComplete?: boolean;
  rawFormData: any;
  stepData: any;
  surveyId?: string;
}

export interface SurveyContext {
  userId: number;
  affinities: string[];
  steps: SurveyStepOptions[];
  step: string;
  index: number;
  isTransitioning: boolean;
  isVisible: boolean;
  show(): void;
  readyToSubmit: boolean;
  setReadyToSubmit: (readyToSubmit: boolean) => void;
  submitStep(options: SubmitStepOptions): void;
  surveyId: string;
  header: Header;
  updateHeader(header: Partial<Header>): void;
  createSurvey(profileType: string): Promise<{ surveyId: string }>;
  trackVirtualPageView(pagePath: string): void;
  goToPreviousStep(): void;
  formData: SurveyFormData;
  useAnonymousLoginEffect(onReady: () => void): { waitingForAnonLogin: boolean };
}

export interface SurveyFormData {
  demographics: DemographicsFormData;
  education: EducationFormData;
  employer: EmployerFormData;
  job: JobFormData;
  pay: PayFormData;
  skills: SkillsFormData;
}

export interface SurveyQueryParameters {
  country?: string;
  jobSituation?: 'Current Job' | 'Job Offer';
  jobTitle?: string;
  location?: string;
  yearsOfExperience?: number;
}

function getSurveyQueryParameters(): SurveyQueryParameters {
  const surveyQueryParameters: SurveyQueryParameters = {};
  new URLSearchParams(window.location.search).forEach((value, key) => {
    switch (key) {
      case 'country':
        surveyQueryParameters.country = value;
        break;
      case 'jobSituation':
        if (value === 'Current Job' || value === 'Job Offer') {
          surveyQueryParameters.jobSituation = value;
        }
        break;
      case 'jobTitle':
        surveyQueryParameters.jobTitle = value;
        break;
      case 'location':
        surveyQueryParameters.location = value;
        break;
      case 'yearsOfExperience':
        if (isNumeric(value)) {
          surveyQueryParameters.yearsOfExperience = +value;
        }
        break;
    }
  });
  return surveyQueryParameters;
}

const [useSurveyContext, ContextProvider] = createGenericContext<SurveyContext>();

const SurveyProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [affinities, setAffinities] = useState<string[]>([]);

  const [header, setHeader] = useState<Header>({
    additionalInfo: '',
    title: '',
    description: '',
  });

  const [index, setIndex] = useState(0);
  // use to prevent duplicate page views
  const [trackedPages, setTrackedPages] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [, setCookie] = useCookies([HAS_COMPLETED_SIMPLE_SURVEY_COOKIE, SESSION_COOKIE]);
  const [step, setStep] = useState<string>('');
  const [steps, setSteps] = useState<SurveyStepOptions[]>([]);
  const [surveyId, setSurveyId] = useState<string>('');
  const [userId, setUserId] = useState<number>(0);

  // TODO: Remove once we know if IDs become available during later steps in the survey
  const [trackingIds, setTrackingIds] = useState<{ ga: string | null; optimizely: string | null }>({
    ga: null,
    optimizely: null,
  });
  function loadTrackingIds(logDiffStep: string | null = null) {
    const clientId = getGaClientId();
    const visitorId = getVisitorId();

    if (logDiffStep) {
      if (clientId !== trackingIds.ga) {
        datadogLogs.logger.warn(`GA ID changed from ${trackingIds.ga} to ${clientId} navigating to ${logDiffStep}`);
      }
      if (visitorId !== trackingIds.optimizely) {
        datadogLogs.logger.warn(
          `Optimizely ID changed from ${trackingIds.optimizely} to ${visitorId} navigating to ${logDiffStep}`
        );
      }
    }

    setTrackingIds({ ga: clientId, optimizely: visitorId });
  }

  const { country, jobSituation, jobTitle, location, yearsOfExperience } = getSurveyQueryParameters();

  const useAnonymousLoginEffect = (onReady: () => void) => {
    const [waitingForAnonLogin, setWaitingForAnonLogin] = useState(true);

    useEffect(() => {
      createAnonymousLogin().then((userId) => {
        setWaitingForAnonLogin(false);
        setUserId(userId);
        onReady();
      });
    }, []);

    return { waitingForAnonLogin };
  };

  const [formData, setFormData] = useState<SurveyFormData>({
    demographics: {
      raceEthnicity: [],
      gender: '',
    },
    education: {
      degree: '',
      certifications: [],
    },
    employer: {
      employerType: '',
      employerProductOrBusiness: '',
      employerName: '',
      numberOfEmployees: undefined,
      governmentContractor: undefined,
    },
    job: {
      jobTitle: '',
      yearsOfExperience: undefined,
      location: '',
      country: '',
      jobSituation: undefined,
      payType: 'Annual',
      currency: 'USD',
      salaryReportedFor: undefined,
    },
    pay: {
      annualBonus: undefined,
      annualProfitShare: undefined,
      annualSalesCommissions: undefined,
      annualSalesVolume: undefined,
    },
    skills: {
      isSupervisor: undefined,
      budgetManaged: undefined,
      quantitySupervised: undefined,
      skills: [],
    },
  });

  useEffect(() => {
    // load survey steps
    setSteps(SURVEY_STEPS);
    setStep(SURVEY_STEPS[0].id);

    setFormData((prev) => ({
      ...prev,
      job: {
        jobTitle: jobTitle ?? '',
        yearsOfExperience,
        location: location ?? '',
        country: country ?? '',
        jobSituation,
      },
    }));

    loadTrackingIds();
  }, []);

  const show = () => {
    if (!isVisible) {
      setIsVisible(true);
    }
  };

  const createSurvey = async (profileType: string) => {
    if (surveyId) {
      // survey already created
      return { surveyId };
    }

    setIsTransitioning(true);

    try {
      const response = await SurveyApi.createSurvey();

      await SurveyApi.saveAnswers(response.survey.id, [
        {
          questionId: 'profiletype',
          properties: { isDefaultAnswer: false },
          values: [profileType],
          skipped: false,
        },
        {
          questionId: 'flow',
          properties: { isDefaultAnswer: false },
          values: ['from-simple-survey'],
          skipped: false,
        },
      ]);

      setSurveyId(response.survey.id);
      return { surveyId: response.survey.id };
    } catch (e) {
      setIsTransitioning(false);
      throw e;
    }
  };

  const updateHeader = (data: Partial<Header>) => setHeader({ ...header, ...data });

  const moveToNext = () => {
    setIndex((prevIndex) => {
      loadTrackingIds(SURVEY_STEPS[prevIndex + 1]?.id);
      return prevIndex + 1;
    });

    setIsTransitioning(false);
  };

  const moveToPrevious = () => setIndex((prevState) => prevState - 1);

  type FormDataMap = { [key: string]: string };
  const stepIdentityMap: FormDataMap = {
    raceEthnicity: 'demographics',
    degree: 'education',
    employerType: 'employer',
    jobTitle: 'job',
    isSupervisor: 'skills',
    payType: 'pay',
  };

  const submitStep = (options: SubmitStepOptions) => {
    const completeStep = options.completeStep ?? true;
    const markComplete = options.markComplete ?? false;

    const formDataKey = Object.keys(options.rawFormData).find((key) => Object.hasOwn(options.rawFormData, key));

    if (formDataKey) {
      setFormData((prevState) => ({
        ...prevState,
        [stepIdentityMap[formDataKey]]: options.rawFormData,
      }));
    }

    if (completeStep) {
      setIsTransitioning(true);

      if (!markComplete) {
        SurveyApi.saveAnswers(options.surveyId ?? surveyId, options.stepData)
          .then(({ affinities }) => {
            setAffinities(affinities ?? []);
            moveToNext();
          })
          .catch(console.error);
      } else {
        SurveyApi.saveAnswers(options.surveyId ?? surveyId, options.stepData)
          .then(({ affinities }) => {
            setAffinities(affinities ?? []);

            SurveyApi.completeSurvey(options.surveyId ?? surveyId)
              .then(() => {
                setCookie(HAS_COMPLETED_SIMPLE_SURVEY_COOKIE, 'true');
                moveToNext();
              })
              .catch(console.error);
          })
          .catch(console.error);
      }
    }
  };

  const trackVirtualPageView = (pagePath: string) => {
    if (trackedPages.includes(pagePath)) {
      // page view has already been tracked
      return;
    }

    setTrackedPages((prevPages) => [...prevPages, pagePath]);

    const virtualPageUrl = `${PAYSCALE_BASE_URL}/survey${pagePath}#landing`;
    trackGoogleAnalyticsPageView(pagePath, virtualPageUrl, surveyId);
  };

  const ctx: SurveyContext = {
    userId,
    affinities,
    steps,
    step,
    index,
    isTransitioning,
    isVisible,
    show,
    readyToSubmit,
    setReadyToSubmit,
    submitStep,
    surveyId,
    header,
    updateHeader,
    createSurvey,
    trackVirtualPageView,
    goToPreviousStep: moveToPrevious,
    formData,
    useAnonymousLoginEffect,
  };

  return <ContextProvider value={ctx}>{children}</ContextProvider>;
};
SurveyProvider.displayName = 'SurveyProvider';

export { useSurveyContext, SurveyProvider };
