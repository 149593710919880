import { css, useTheme } from '@mui/material/styles';
import { Link } from '@mui/material';

export default function PrivacyFooter() {
  const theme = useTheme();
  return (
    <footer
      css={css`
        padding: 15px;
        text-align: center;
        color: ${theme.palette.text.primary};
        font-size: 14px;
        max-width: 850px;
        margin: auto;
      `}
    >
      <p>
        Payscale takes your privacy seriously and is sensitive to the nature of information collected on this site. By
        sharing your responses with us you will be joining more than 12 million users who have anonymously shared
        salary-related data. To learn more, check out our Privacy Policy below.
      </p>
      <span
        css={css`
          display: flex;
          justify-content: center;
          gap: 4px;
        `}
      >
        <Link
          href={import.meta.env.VITE_TERMS_OF_USE_URL}
          target="_blank"
          underline="none"
          sx={{ color: 'text.primary' }}
          aria-label="View our Terms of Use in a new tab"
        >
          Terms of Use
        </Link>
        <span>|</span>
        <Link
          href={import.meta.env.VITE_PRIVACY_POLICY_URL}
          target="_blank"
          underline="none"
          sx={{ color: 'text.primary' }}
          aria-label="View our Privacy Policy in a new tab"
        >
          Privacy Policy
        </Link>
      </span>
    </footer>
  );
}
