import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { css } from '@emotion/react';
import Button from './Button';
import { ButtonProps } from '@mui/material';

export default function NavigationButton({
  children,
  direction,
  color = 'primary',
  disabled = false,
  type = 'submit',
  variant = 'contained',
  ...props
}: NavigationButtonProps) {
  if (direction === 'forward') {
    return (
      <Button
        css={css`
          .MuiButton-endIcon {
            margin-left: 32px;
          }
        `}
        color={color}
        disabled={disabled}
        type={type}
        variant={variant}
        {...props}
        endIcon={<ArrowForwardIcon />}
      >
        {children}
      </Button>
    );
  }

  if (direction === 'backward') {
    return (
      <Button
        css={css`
          .MuiButton-startIcon {
            margin-right: 32px;
          }
        `}
        color={color}
        disabled={disabled}
        type={type}
        variant={variant}
        {...props}
        startIcon={<ArrowBackIcon />}
      >
        {children}
      </Button>
    );
  }

  throw new Error('Invalid direction');
}

export interface NavigationButtonProps extends ButtonProps {
  direction: 'forward' | 'backward';
}
