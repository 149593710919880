import axios from 'axios';
import { SURVEY_API_URL } from '@/constants/urls';
import { validateStatusCode } from '@/util/validateStatusCode';

export async function completeSurvey(surveyId: string): Promise<void> {
  const response = await axios.post(
    `${SURVEY_API_URL}/${surveyId}`,
    {},
    {
      withCredentials: true,
    }
  );

  validateStatusCode(response);
}
