import { datadogLogs } from '@datadog/browser-logs';

declare global {
  interface Window {
    optimizely:
      | {
          get: (key: string) => { randomId: string } | undefined;
        }
      | undefined;
  }
}

export const getVisitorId = () => getVisitorIdFromOptimizely() ?? getVisitorIdFromLocalStorage();

const getVisitorIdFromOptimizely = () => {
  if (!window.optimizely) {
    datadogLogs.logger.warn('Failed to get Optimizely object from window');
    return null;
  }

  const optimizelyVisitorId = window.optimizely?.get('visitor_id');
  if (!optimizelyVisitorId) {
    datadogLogs.logger.warn('Failed to get visitorId from Optimizely', {
      optimizelyVisitorId,
    });
    return null;
  }

  const randomId = optimizelyVisitorId?.randomId;

  if (!randomId) {
    datadogLogs.logger.warn('Failed to get randomId from Optimizely visitorId object');
    return null;
  }

  return randomId;
};

/*
  Fallback method for getting visitorId from localstorage when Optimizely object is not available
 */
const getVisitorIdFromLocalStorage = () => {
  try {
    const visitorProfileRecord = Object.entries(localStorage).filter(([k]) => k.includes('visitor_profile'))[0]?.[1];
    const visitorId = JSON.parse(visitorProfileRecord)?.profile?.visitorId as string;

    if (!visitorId) {
      datadogLogs.logger.warn('Failed to get visitorId from localstorage');
      return null;
    }

    return visitorId;
  } catch (e) {
    datadogLogs.logger.warn('Failed to get visitorId from localstorage');
    return null;
  }
};
