declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

export const trackGoogleAnalyticsPageView = (pageName: string, virtualPageUrl: string, surveyId: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'virtualPageView',
    pageTitle: pageName,
    pageLocation: virtualPageUrl,
    eventSurveyId: surveyId
  });
};

// TODO: This will be used once there is no longer a Survey Start event fired by the legacy /wizards/choose.aspx page via iframe
export const trackSurveyStart = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nonInteractiveEvent',
    eventCategory: 'survey',
    eventAction: 'start',
  });
};

export const trackCompensationSubmitted = (userId: number, surveyId: string, jobSituation?: 'Current Job' | 'Job Offer') => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nonInteractiveEvent',
    eventCategory: 'survey',
    eventAction: 'compensation submit',
    eventLabel: jobSituation === 'Current Job' ? 1 : 2,
    eventUserId: userId,
    eventSurveyId: surveyId
  });
};

export const trackSurveyCompleted = (userId: number, surveyId: string, jobSituation?: 'Current Job' | 'Job Offer') => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nonInteractiveEvent',
    eventCategory: 'survey',
    eventAction: 'completed',
    eventLabel: jobSituation === 'Current Job' ? 1 : 2,
    eventUserId: userId,
    eventSurveyId: surveyId
  });
};
