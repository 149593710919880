import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CookiesProvider } from 'react-cookie';
import theme from './muiTheme';
import SurveyPage from '@/components/pages/SurveyPage';
import { initializeDatadog } from '@/util/datadog';
import { ErrorBoundary } from 'react-error-boundary';

initializeDatadog();

function App() {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <CookiesProvider>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <SurveyPage />
        </ThemeProvider>
      </CookiesProvider>
    </ErrorBoundary>
  );
}

export default App;
