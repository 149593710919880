import { Button as MuiButton, ButtonProps } from '@mui/material';
import { css } from '@emotion/react';

export default function Button({
  children,
  color = 'primary',
  variant = 'contained',
  disabled = false,
  ...props
}: ButtonProps) {
  return (
    <MuiButton
      css={css`
        padding: 8px 24px;
        border-radius: 25px;
      `}
      color={color}
      variant={variant}
      disabled={disabled}
      {...props}
    >
      {children}
    </MuiButton>
  );
}
