import { css, useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useSurveyContext } from '@/providers/SurveyProvider';

export default function Form({ children, onSubmit }: FormProps) {
  const { isTransitioning } = useSurveyContext();
  const theme = useTheme();
  return (
    <form
      id="survey-form"
      onSubmit={onSubmit}
      // duplicate of styles in <CompletionStep/>
      css={css`
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 32px;

        ${theme.breakpoints.up('sm')} {
          padding: 0 60px;
        }

        ${theme.breakpoints.up('md')} {
          padding: 0 116px;
        }

        pointer-events: ${isTransitioning ? 'none' : 'unset'};
      `}
    >
      {children}
    </form>
  );
}

interface FormProps {
  children: ReactNode;
  onSubmit: () => void;
}
