import { TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { TextInputProps } from './types';
import { generateRules } from '@/stories/components/Input/generateRules';

const TextInput = <TFieldValues extends FieldValues>({
  control,
  name,
  required,
  customRules,
  label,
  defaultValue,
  onChange,
  shouldUnregister,
  color = 'primary',
  variant = 'outlined',
  size = 'small',
  ...rest
}: TextInputProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={generateRules({ required }, customRules)}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          onChange={(e) => {
            field.onChange(e);
            onChange && onChange(e);
          }}
          label={`${label}${required ? ' *' : ''}`}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          color={color}
          variant={variant}
          size={size}
          {...rest}
        />
      )}
    />
  );
};

export default TextInput;
