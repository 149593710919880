import { completeSurvey } from './completeSurvey';
import { createSurvey } from './createSurvey';
import { saveAnswers } from '@/api/survey/saveAnswers';
import { getTopOptions } from '@/api/survey/getTopOptions';

export const SurveyApi = {
  createSurvey,
  saveAnswers,
  completeSurvey,
};

export const PopularAnswersApi = {
  getTopOptions,
};
