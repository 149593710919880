import { Controller, FieldValues } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import { CheckboxInputProps, CheckboxOption } from './types';

export default function VerticalCheckboxGroupInput<TFieldValues extends FieldValues>({
  control,
  name,
  label,
  defaultValue,
  onChange,
  options,
  shouldUnregister,
  color = 'primary',
  size = 'small',
  ...rest
}: CheckboxInputProps<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FormControl
          component="fieldset"
          sx={{
            display: 'flex',
            columnGap: 40,
          }}
        >
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup
            sx={{
              display: 'grid',
              gridTemplateRows: 'auto',
              gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            }}
          >
            {options.map((option: CheckboxOption) => (
              <FormControlLabel
                key={option.label}
                control={
                  <Checkbox
                    checked={field.value.includes(option.label)}
                    name={name}
                    onChange={(event, checked) => {
                      const checkedOptions = checked
                        ? [...field.value, option.label]
                        : field.value.filter((value: string) => value !== option.label);

                      field.onChange(checkedOptions);

                      if (onChange) {
                        onChange(checkedOptions);
                      }
                    }}
                    ref={field.ref}
                    color={color}
                    size={size}
                    {...rest}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
          {fieldState.error && (
            <FormHelperText error sx={{ margin: '4px 14px 0' }}>
              {fieldState.error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
