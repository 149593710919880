import axios from 'axios';
import { POPULAR_ANSWERS_API_URL } from '@/constants/urls';
import { validateStatusCode } from '@/util/validateStatusCode';
import { z } from 'zod';
import { datadogLogs } from '@datadog/browser-logs';

const getTopOptionsSchema = z.array(z.string());
export type TopOptions = z.infer<typeof getTopOptionsSchema>;

export async function getTopOptions(field?: string, jobTitle?: string, country?: string, location?: string) {
  if (!field || !jobTitle || !country || !location) {
    datadogLogs.logger.warn(
      [
        'Calling getTopOptions with missing parameters',
        `field: ${field}`,
        `jobTitle: ${jobTitle}`,
        `country: ${country}`,
        `location: ${location}`,
      ].join(' ')
    );
    return [] as TopOptions;
  }

  const response = await axios.get<TopOptions>(
    encodeURI(
      `${POPULAR_ANSWERS_API_URL}?fg=${field}&f=${field}&job=${jobTitle}&country=${country}&location=${location}`
    )
  );

  validateStatusCode(response);

  const validatedData = getTopOptionsSchema.safeParse(response.data);

  if (!validatedData.success) {
    datadogLogs.logger.warn(
      [
        'Error validating get top options response',
        JSON.stringify(validatedData.error.format()),
        JSON.stringify(response.data),
      ].join(' ')
    );

    return [] as TopOptions;
  }

  return validatedData.data;
}
