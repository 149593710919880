import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSurveyContext } from '@/providers/SurveyProvider';
import Form from '@/components/Survey/Form';
import { RadioInput, LikertScaleInput, NumberInput } from '@/stories/components/Input';
import { Question } from '@/abstractions/question';
import { LikertAnswer } from '@/stories/components/Input/LikertScaleInput';
import GridColumns from '@/components/shared/GridColumns';

function PayStep() {
  const { formData, submitStep, trackVirtualPageView, updateHeader, setReadyToSubmit } = useSurveyContext();

  const { control, formState, handleSubmit } = useForm<PayFormData>({
    defaultValues: formData.pay,
  });

  const onSubmit = handleSubmit((payData) => {
    submitStep({ rawFormData: payData, stepData: mapFormData(payData) });
  });

  useEffect(() => {
    updateHeader({
      additionalInfo:
        'From bonuses to profit sharing, there are many ways to value the work that you do. It’s important to understand all aspects of your compensation, so you have the power and knowledge to negotiate your worth! Tell us how you’re paid so we can better assess your compensation.',
      title: 'Your Pay',
      description: 'Tell us a little bit more about how you’re paid so we can better assess your value.',
    });

    trackVirtualPageView('/your-pay');
  }, []);

  useEffect(() => {
    // isValid will be false when form is loaded - check for errors instead
    setReadyToSubmit(!Object.keys(formState.errors).length);
  }, [formState]);

  return (
    <Form onSubmit={onSubmit}>
      <GridColumns columns={2}>
        <NumberInput name="annualBonus" control={control} label="Annual Bonus" min={0} />

        <NumberInput name="annualProfitShare" control={control} label="Annual Profit Share" min={0} />

        <NumberInput name="annualSalesCommissions" control={control} label="Annual Sales Commission" min={0} />

        <NumberInput name="annualSalesVolume" control={control} label="Annual Sales Volume (Gross Sales)" min={0} />
      </GridColumns>

      <div>
        {formData.job.jobSituation === 'Current Job' && (
          <div css={{ display: 'grid', gap: 40 }}>
            <LikertScaleInput
              name="transparentPay"
              control={control}
              label="How pay is determined at my company is a transparent process."
            />
            <LikertScaleInput name="fairPay" control={control} label="I feel that I am paid fairly." />
            <RadioInput
              name="marketPosition"
              control={control}
              label="How do you think your current pay compares to other employees like you?"
              options={[{ label: 'Below Average' }, { label: 'Average' }, { label: 'Above Average' }]}
            />
          </div>
        )}
        {formData.job.jobSituation === 'Job Offer' && (
          <RadioInput
            name="salaryRangeProvidedAt"
            control={control}
            label="At which point in the hiring process were you provided with a salary range?"
            options={[
              { label: 'Job Posting' },
              { label: 'Initial Interview' },
              { label: 'With the Offer' },
              { label: 'Never' },
            ]}
          />
        )}
      </div>
    </Form>
  );

  function mapFormData(data: PayFormData): Question[] {
    const mappedData: Question[] = [
      // BonusPay is boolean representing whether there is an AnnualBonus or not
      // In legacy survey, this is true by default
      {
        questionId: 'BonusPay',
        values: [!!data.annualBonus],
        skipped: !data.annualBonus,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'AnnualBonus',
        values: [data.annualBonus],
        skipped: !data.annualBonus,
        properties: { isDefaultAnswer: false },
      },
      // ProfitSharePay is boolean representing whether there is an AnnualProfitShare or not
      // In legacy survey, this is true by default
      {
        questionId: 'ProfitSharePay',
        values: [!!data.annualProfitShare],
        skipped: !data.annualProfitShare,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'ProfitShare',
        values: [data.annualProfitShare],
        skipped: !data.annualProfitShare,
        properties: { isDefaultAnswer: false },
      },
      {
        // SalesCommissionPay is boolean representing whether there is an AnnualSalesCommissions or not
        // In legacy survey, this is false by default
        questionId: 'SalesCommissionPay',
        values: [!!data.annualSalesCommissions],
        skipped: !data.annualSalesCommissions,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'AnnualSalesCommissions',
        values: [data.annualSalesCommissions],
        skipped: !data.annualSalesCommissions,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'AnnualSalesVolume',
        values: [data.annualSalesVolume],
        skipped: !data.annualSalesVolume,
        properties: { isDefaultAnswer: false },
      },
    ];

    if (formData.job.jobSituation === 'Current Job') {
      mappedData.push({
        questionId: 'TransparentPay',
        values: [data.transparentPay],
        skipped: !data.transparentPay,
        properties: { isDefaultAnswer: !!formState.dirtyFields.transparentPay },
      });

      mappedData.push({
        questionId: 'FairPay',
        values: [data.fairPay],
        skipped: !data.fairPay,
        properties: { isDefaultAnswer: !!formState.dirtyFields.fairPay },
      });

      mappedData.push({
        questionId: 'MarketPosition',
        values: [data.marketPosition],
        skipped: !data.marketPosition,
        properties: { isDefaultAnswer: !!formState.dirtyFields.marketPosition },
      });
    } else if (formData.job.jobSituation === 'Job Offer') {
      mappedData.push({
        questionId: 'SalaryRangeProvidedAt',
        values: [data.salaryRangeProvidedAt],
        skipped: !data.salaryRangeProvidedAt,
        properties: { isDefaultAnswer: !!formState.dirtyFields.salaryRangeProvidedAt },
      });
    }

    return mappedData;
  }
}

export interface PayFormData {
  annualBonus?: number;
  annualProfitShare?: number;
  annualSalesCommissions?: number;
  annualSalesVolume?: number;
  salaryRangeProvidedAt?: 'Job Posting' | 'Initial Interview' | 'With the Offer' | 'Never';
  transparentPay?: LikertAnswer;
  fairPay?: LikertAnswer;
  marketPosition?: 'Below Average' | 'Average' | 'Above Average';
}

export default PayStep;
