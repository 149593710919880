import { TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { NumberInputProps, ValidationRules } from './types';

const NumericInput = <TFieldValues extends FieldValues>({
  control,
  name,
  required,
  label,
  min,
  max,
  shouldUnregister,
  defaultValue,
  color = 'primary',
  variant = 'outlined',
  size = 'small',
  ...rest
}: NumberInputProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={generateRules()}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          onChange={(e) => field.onChange(+e.target.value)}
          type="number"
          value={field.value ?? ''}
          label={`${label}${required ? ' *' : ''}`}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          inputProps={{ min, max }}
          color={color}
          variant={variant}
          size={size}
          {...rest}
        />
      )}
    />
  );

  function generateRules() {
    const rules: ValidationRules = {};
    if (required) {
      rules.required = { value: true, message: 'Required' };
    }

    if (min) {
      rules.min = { value: min, message: 'Too small' };
    }

    if (max) {
      rules.max = { value: max, message: 'Too large' };
    }

    return rules;
  }
};

export default NumericInput;
