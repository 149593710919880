import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSurveyContext } from '@/providers/SurveyProvider';
import Form from '@/components/Survey/Form';
import { AutocompleteInput, DropdownInput, RadioInput, NumberInput } from '@/stories/components/Input';
import { DropdownOptions } from '@/stories/components/Input/types';
import { isNullOrEmpty } from '@/util/isNullOrEmpty';
import { Question } from '@/abstractions/question';
import useAutocomplete from '@/hooks/useAutocomplete';

function EmployerStep() {
  const { formData, submitStep, trackVirtualPageView, updateHeader, setReadyToSubmit } = useSurveyContext();
  const { searchEmployers, searchProductBusiness } = useAutocomplete();

  const form = useForm<EmployerFormData>({
    defaultValues: formData.employer,
  });

  const { control, formState, handleSubmit, watch } = form;
  const watchProductBusiness = watch('employerProductOrBusiness') || '';
  const watchEmployerName = watch('employerName') || '';
  const watchGovernmentContractor = watch('governmentContractor');

  useEffect(() => {
    updateHeader({
      additionalInfo:
        'Pay characteristics can change depending on your employer. Different industries or employers have unique working conditions and job requirements that can impact pay. Some sectors or large companies can afford to pay more for competitive jobs.',
      title: 'Your Employer',
      description:
        "Details about your employer's type and industry can affect your pay and are required to provide you the most precise salary range possible.",
    });

    trackVirtualPageView('/your-employer');
  }, []);

  const onSubmit = handleSubmit((employerData) =>
    submitStep({
      rawFormData: employerData,
      stepData: mapFormData(employerData),
    })
  );

  useEffect(() => {
    // isValid will be false when form is loaded - check for errors instead
    setReadyToSubmit(!Object.keys(formState.errors).length);
  }, [formState]);

  return (
    <Form onSubmit={onSubmit}>
      <DropdownInput
        name="employerType"
        control={control}
        label="Type of Employer"
        options={employerTypeOptions}
        required
      />

      <AutocompleteInput
        name="employerProductOrBusiness"
        control={control}
        label="Employer's Product/Business"
        required
        currentValue={watchProductBusiness}
        getOptions={searchProductBusiness}
        allowFreeText
      />

      <AutocompleteInput
        name="employerName"
        control={control}
        label="Employer Name"
        currentValue={watchEmployerName}
        getOptions={searchEmployers}
        allowFreeText
      />

      <NumberInput
        name="numberOfEmployees"
        control={control}
        label="Number of Employees in Organization"
        min={0}
        max={5000000} // Walmart has 2.3 million employees as of 11/14/2022
        shouldUnregister
      />

      <RadioInput
        defaultValue={watchGovernmentContractor}
        name="governmentContractor"
        label="Is your employer primarily a government contractor?"
        options={[{ label: 'Yes' }, { label: 'No' }]}
        control={control}
      />
    </Form>
  );

  function mapFormData(data: EmployerFormData): Question[] {
    return [
      {
        // Not skipped because it's required
        questionId: 'OrganizationType',
        values: [data.employerType],
        skipped: false,
        properties: { isDefaultAnswer: false },
      },
      {
        // Not skipped because it's required
        questionId: 'EmployerProductActivity',
        values: [data.employerProductOrBusiness],
        skipped: false,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'EmployerName',
        values: [data.employerName],
        skipped: isNullOrEmpty(data.employerName),
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'AverageSizeCompetitor',
        values: [data.numberOfEmployees],
        skipped: !data.numberOfEmployees,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'GovernmentContractor',
        values: [data.governmentContractor],
        skipped: !data.governmentContractor,
        properties: { isDefaultAnswer: false },
      },
    ];
  }
}

const employerTypeOptions: DropdownOptions[] = [
  'Company',
  'College / University',
  'Contract',
  'Fellowship',
  'Foundation / Trust',
  'Franchise',
  'Government - Federal',
  'Government - State & Local',
  'Military',
  'Non-Profit Organization',
  'Private Practice/Firm',
  'School / School District',
  'Self-Employed',
  'Team',
  'Other Organization',
].map((label) => ({ label, value: label }));

export interface EmployerFormData {
  employerType?: string;
  employerProductOrBusiness?: string;
  employerName?: string;
  numberOfEmployees?: number;
  governmentContractor?: 'Yes' | 'No';
}

export default EmployerStep;
