import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs, type LogsEvent } from '@datadog/browser-logs';
import { version } from '../../package.json';

const RUM_ENVIRONMENTS = ['prod', 'prestaging'];
const TRACKING_URLS = [
  'events.launchdarkly.com',
  'px.ads.linkedin.com',
  'stats.g.doubleclick.net',
  'localhost:12387', // Facebook tracking pixel https://payscale.atlassian.net/browse/CN-856
];

let initialized = false;

export function initializeDatadog() {
  const env = import.meta.env.VITE_DATADOG_ENVIRONMENT;

  if (!RUM_ENVIRONMENTS.includes(env)) {
    return;
  }

  if (initialized) {
    return;
  }

  const sharedConfig = {
    clientToken: 'pub68ade75aa74453d58e9b20723a0e9a48',
    site: 'datadoghq.com',
    service: 'consumer-survey',
    env,
  };

  datadogRum.init({
    ...sharedConfig,
    applicationId: 'a31c52ba-ed26-49c4-b66b-e1a6085ace2c',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    ...sharedConfig,
    forwardConsoleLogs: ['error'],
    beforeSend: filterNoiseFromTrackingUrls,
  });

  initialized = true;
}

function filterNoiseFromTrackingUrls(log: LogsEvent) {
  if (log?.http?.url) {
    const { host } = new URL(log.http.url);
    return !TRACKING_URLS.includes(host);
  }

  return true;
}
