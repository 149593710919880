import { useEffect, useState } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { AutocompleteInputProps } from './types';
import { useDebounce } from '@/hooks/useDebounce';
import { generateRules } from './generateRules';

const AutocompleteInput = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  currentValue,
  customRules,
  getOptions,
  allowFreeText = false,
  size = 'small',
  required,
  shouldUnregister,
  onChange,
  color = 'primary',
  variant = 'outlined',
  ...rest
}: AutocompleteInputProps<TFieldValues>) => {
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const updateOptions = (inputValue: string) => getOptions(inputValue).then(setOptions).catch(console.error);

  const updateOptionsDebounce = useDebounce(updateOptions, 300, [getOptions]);

  useEffect(() => {
    if (!inputValue) return;
    updateOptionsDebounce(inputValue)?.catch(console.error);
  }, [inputValue]);

  // If free text is allowed, ensure current input value is an option
  function currentOptions() {
    if (!allowFreeText) {
      return options;
    }

    if (options.length && !options.includes(inputValue)) {
      return [inputValue, ...options];
    }

    if (inputValue) {
      return [inputValue];
    }

    return [];
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={generateRules({ required }, customRules)}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          autoSelect
          blurOnSelect
          noOptionsText="No Results"
          options={currentOptions()}
          filterOptions={(x) => x} // disable filtering
          inputValue={inputValue}
          onInputChange={(_, value) => setInputValue(value)}
          value={currentValue}
          onChange={(_, value) => {
            field.onChange(value);

            if (onChange) {
              onChange(value);
            }
          }}
          size={size}
          isOptionEqualToValue={(option, value) => option === value || (!!option && option.startsWith(inputValue))}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={`${label}${required ? ' *' : ''}`}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              color={color}
              variant={variant}
              {...rest}
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteInput;
