import axios from 'axios';
import { DETECT_GEOLOCATION_URL } from '@/constants/urls';
import { validateStatusCode } from '@/util/validateStatusCode';
import { z } from 'zod';
import { datadogLogs } from '@datadog/browser-logs';

const detectLocationResponseSchema = z.object({
  city: z.string().default(''),
  state: z.string().default(''),
  country: z.string().default('United States'),
});

export async function detectLocation() {
  const response = await axios.get(DETECT_GEOLOCATION_URL, {});

  if (response.status === 404) {
    return { city: '', state: '', country: '' };
  }

  validateStatusCode(response);

  const mapResponse = {
    city: response.data['City'],
    state: response.data['State'],
    country: response.data['Country'],
  };

  const validatedData = detectLocationResponseSchema.safeParse(mapResponse);

  if (!validatedData.success) {
    datadogLogs.logger.warn(
      [
        'Error validating detect location response',
        JSON.stringify(validatedData.error.format()),
        JSON.stringify(response.data),
      ].join(' ')
    );

    throw new Error();
  }

  return validatedData.data;
}
