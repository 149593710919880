import { datadogLogs } from '@datadog/browser-logs';

export function getGaClientId() {
  try {
    const cookies = document?.cookie?.split('; ');
    for (let i = 0; i < cookies?.length; i++) {
      const cookie = cookies[i].split('=');
      if (cookie && cookie[0] === '_ga') {
        const gaCookieParts = cookie[1].split('.');
        return gaCookieParts[2] + '.' + gaCookieParts[3];
      }
    }
  } catch (e) {
    datadogLogs.logger.warn('Error getting GA client ID');
  }

  return null;
}
