import { css, useTheme } from '@mui/material/styles';

export default function PageHeader() {
  const theme = useTheme();
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        padding: 20px 0 10px;
        border-bottom: 1px solid ${theme.palette.divider};

        .navbar-logo {
          height: 32px;
        }
      `}
    >
      <a href="/">
        <img
          className="navbar-logo"
          src={'//cdn-payscale.com/content/assets/payscale-logo-color.svg'}
          alt="Payscale logo"
        />
      </a>
    </div>
  );
}
