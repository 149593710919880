import { ChipProps } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import * as Styled from './styles';

export interface AutocompleteChipProps extends ChipProps {
  active?: boolean;
}

export default function AutocompleteChip({ active = false, ...rest }: AutocompleteChipProps) {
  return (
    <Styled.Chip
      data-active={active}
      deleteIcon={
        // increase specificity to override color from AutocompleteChip component
        <ClearIcon sx={{ '&&': { color: 'primary.contrastText', fontSize: 16 } }} />
      }
      {...rest}
    />
  );
}
