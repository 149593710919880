import { useSurveyContext } from '@/providers/SurveyProvider';
import CompletionStep from '@/components/steps/CompletionStep';
import Header from './Header';
import SurveyStep from './Step';
import { SurveyStepOptions } from '@/abstractions/surveyStepOptions';
import { css, useTheme } from '@mui/material/styles';
import { NavigationButton } from '@/stories/components/Button';
import PageHeader from '@/stories/components/PageHeader';
import PrivacyFooter from '@/stories/components/PrivacyFooter';
import BannerHeader from '@/stories/components/BannerHeader';
import { useEffect } from 'react';
import { trackSurveyStart } from '@/util/dataLayer';

const Survey = ({ steps }: SurveyProps) => {
  const { goToPreviousStep, index, isVisible, isTransitioning, readyToSubmit } = useSurveyContext();
  const theme = useTheme();
  const enabledSteps = steps.filter((x) => x.enabled);
  const isFirstStep = index === 0;
  const isLastStep = enabledSteps.length <= index;

  if (!isVisible) {
    return null;
  }

  useEffect(() => {
    trackSurveyStart();
  }, []);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-height: 100%;
      `}
    >
      <PageHeader />
      <BannerHeader />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;

          padding: 32px 0;
        `}
      >
        <div
          css={css`
            margin: 20px 0 30px 0;

            ${theme.breakpoints.up('sm')} {
              margin: 30px 0;
            }
          `}
        >
          <Header />
          {isLastStep ? <CompletionStep /> : <SurveyStep id={enabledSteps[index].id} />}
        </div>

        <div
          css={css`
            display: grid;

            grid-template-columns: ${!isFirstStep ? '1fr 1fr' : '1fr'};
            margin: auto 20px 0;
            gap: 20px;

            ${theme.breakpoints.up('sm')} {
              margin: auto 60px 0;
            }

            ${theme.breakpoints.up('md')} {
              margin: auto 116px 0;
            }
          `}
        >
          {!isFirstStep && !isLastStep && (
            <NavigationButton
              direction="backward"
              variant="outlined"
              disabled={isTransitioning}
              css={css`
                ${theme.breakpoints.up('sm')} {
                  margin-right: auto;
                }
              `}
              onClick={goToPreviousStep}
            >
              Back
            </NavigationButton>
          )}

          {!isLastStep && (
            <NavigationButton
              form="survey-form"
              direction="forward"
              disabled={!readyToSubmit || isTransitioning}
              css={css`
                ${theme.breakpoints.up('sm')} {
                  margin-left: auto;
                }
              `}
            >
              {enabledSteps[index].navigationButtonText}
            </NavigationButton>
          )}
        </div>
      </div>
      <PrivacyFooter />
    </div>
  );
};

export interface SurveyProps {
  steps: SurveyStepOptions[];
}

export default Survey;
