import { DependencyList, useCallback } from 'react';
import { debounce, DebouncedFunc } from 'lodash';

export function useDebounce<T extends (...args: any[]) => any>(
  fn: T,
  timeout: number,
  dependencies: DependencyList
): DebouncedFunc<T> {
  return useCallback(debounce(fn, timeout), dependencies as DependencyList);
}
