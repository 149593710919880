import axios from 'axios';
import { z } from 'zod';
import { datadogLogs } from '@datadog/browser-logs';
import { SURVEY_API_URL } from '@/constants/urls';
import { validateStatusCode } from '@/util/validateStatusCode';
import { getVisitorId } from '@/util/optimizely';
import { getGaClientId } from '@/util/getGaClientId';

const createSurveyResponseSchema = z.object({
  survey: z.object({
    id: z.string(),
  }),
});

export type CreateSurveyResponse = z.infer<typeof createSurveyResponseSchema>;

export async function createSurvey() {
  const clientId = getGaClientId();
  const visitorId = getVisitorId();
  const response = await axios.post<CreateSurveyResponse>(
    SURVEY_API_URL,
    { },
    {
      params: { flowName: 'default', analyticsVisitorId: visitorId, googleAnalyticsClientId: clientId },
      withCredentials: true,
    }
  );

  validateStatusCode(response);

  const validatedData = createSurveyResponseSchema.safeParse(response.data);

  if (!validatedData.success) {
    console.error('Error validating create survey response', JSON.stringify(validatedData.error.format()));
    throw new Error();
  }

  if (!visitorId) {
    datadogLogs.logger.warn('Created survey without visitorId', {
      surveyId: validatedData.data.survey.id,
    });
  }

  if (!clientId) {
    datadogLogs.logger.warn('Created survey without clientId', {
      surveyId: validatedData.data.survey.id,
    });
  }

  return validatedData.data;
}
