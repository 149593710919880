import { css, useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';

export default function GridColumns({ children, columns }: GridColumnsProps) {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        row-gap: 20px;

        ${theme.breakpoints.up('sm')} {
          grid-template-columns: repeat(${columns}, 1fr);
          gap: 40px 24px;
        }
      `}
    >
      {children}
    </div>
  );
}

interface GridColumnsProps {
  children: ReactNode;
  columns: number;
}
