import { Controller, FieldValues } from 'react-hook-form';
import { NumberInputWithInapplicableProps } from '@/stories/components/Input/types';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useState } from 'react';

const NumberInputWithInapplicable = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  inapplicableLabel,
  min,
  max,
  shouldUnregister,
  defaultValue,
  ...rest
}: NumberInputWithInapplicableProps<TFieldValues>) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <div>
          <TextField
            {...field}
            type="number"
            value={field.value === inapplicableLabel ? '' : field.value ?? ''}
            label={label}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            inputProps={{ min, max }}
            disabled={disabled}
            fullWidth
            {...rest}
          />
          <FormGroup sx={{ width: 'fit-content' }}>
            <FormControlLabel
              label={inapplicableLabel}
              control={
                <Checkbox
                  {...field}
                  onChange={(e) => {
                    // reset the value if the checkbox is checked
                    if (e.target.checked) {
                      field.onChange(inapplicableLabel);
                      setDisabled(true);
                    } else {
                      field.onChange('');
                      setDisabled(false);
                    }
                  }}
                  {...rest}
                />
              }
            />
          </FormGroup>
        </div>
      )}
    />
  );
};

export default NumberInputWithInapplicable;
