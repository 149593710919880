import { useFlags } from 'launchdarkly-react-client-sdk';
import MaintenancePage from '@/components/pages/MaintenancePage';
import { SurveyProvider } from '@/providers/SurveyProvider';
import Survey from '@/components/Survey';
import { SURVEY_STEPS } from '@/constants/surveySteps';

export default function SurveyPage() {
  const flags = useFlags();

  const bypass = new URLSearchParams(window.location.search).get('bpx') === '067d357b-31d4-49fe-bc34-6fd59ec19b7e';
  if (flags.simpleSurveyMaintenance && !bypass) {
    return <MaintenancePage />;
  }

  return (
    <SurveyProvider>
      <Survey steps={SURVEY_STEPS} />
    </SurveyProvider>
  );
}
