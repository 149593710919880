import axios from 'axios';
import { z } from 'zod';
import { CONSUMER_API_BASE_URL } from '@/constants/urls';
import { validateStatusCode } from '@/util/validateStatusCode';
import { datadogLogs } from '@datadog/browser-logs';

const MINIMUM_AUTOCOMPLETE_LENGTH = 3;
const FIELD = {
  ASSOCIATES_SCHOOL: 'associateSchoolName',
  BACHELORS_SCHOOL: 'bachelorSchoolName',
  CERTIFICATE_SCHOOL: 'certificateSchoolName',
  CERTIFICATION: 'certifications',
  COUNTRY: 'country',
  EMPLOYER: 'employer_name',
  JD_SCHOOL: 'jdSchoolName',
  JOB_TITLE: 'job',
  LOCATION: 'locations',
  MAJOR: 'major',
  MASTERS_SCHOOL: 'masterSchoolName',
  MBA_SCHOOL: 'mbaSchoolName',
  MEDICAL_SCHOOL: 'mdSchoolName',
  PHD_SCHOOL: 'phdSchoolName',
  PRODUCT_BUSINESS: 'productactivity',
  SKILL: 'skill',
};

const suggestionsSchema = z.array(z.string());
export type Suggestions = z.infer<typeof suggestionsSchema>;

export default function useAutocomplete() {
  const isSupportedField = (field: string) => Object.values(FIELD).includes(field);

  async function getAutocomplete(field: string, searchTerm: string) {
    if (!isSupportedField(field)) {
      throw new Error(`Attempted to fetch suggestions for unsupported field: ${field}`);
    }

    const filterSearchTerm = searchTerm?.trim().replace(/\s+/g, ' ');
    if (!filterSearchTerm?.length || filterSearchTerm.length < MINIMUM_AUTOCOMPLETE_LENGTH) {
      return [] as Suggestions;
    }

    const response = await axios.get<Suggestions>(
      `${CONSUMER_API_BASE_URL}/api/autocomplete/${field}/${encodeURIComponent(filterSearchTerm)}`
    );

    validateStatusCode(response);

    const validatedData = suggestionsSchema.safeParse(response.data);

    if (!validatedData.success) {
      datadogLogs.logger.warn(
        [
          'Error validating autocomplete response',
          JSON.stringify(validatedData.error.format()),
          JSON.stringify(response.data),
        ].join(' ')
      );

      return [] as Suggestions;
    }

    return validatedData.data;
  }

  async function getAutocompleteForLocations(country: string, searchTerm: string) {
    const filterSearchTerm = searchTerm?.trim().replace(/\s+/g, ' ');
    if (!country?.length || !filterSearchTerm?.length || filterSearchTerm.length < MINIMUM_AUTOCOMPLETE_LENGTH) {
      return [] as Suggestions;
    }

    const url = new URL(`${CONSUMER_API_BASE_URL}/api/autocomplete/locations`);
    const params = new URLSearchParams();
    params.append('country', country);
    params.append('search', filterSearchTerm);
    url.search = params.toString();

    const response = await axios.get<Suggestions>(url.toString());
    const validatedData = suggestionsSchema.safeParse(response.data);

    if (!validatedData.success) {
      datadogLogs.logger.warn(
        [
          'Error validating autocomplete:country response',
          JSON.stringify(validatedData.error.format()),
          JSON.stringify(response.data),
        ].join(' ')
      );
      return [] as Suggestions;
    }

    return validatedData.data;
  }

  return {
    searchAssociateInstitutions: getAutocomplete.bind(null, FIELD.ASSOCIATES_SCHOOL),
    searchBachelorInstitutions: getAutocomplete.bind(null, FIELD.BACHELORS_SCHOOL),
    searchCertificateInstitutions: getAutocomplete.bind(null, FIELD.CERTIFICATE_SCHOOL),
    searchCertifications: getAutocomplete.bind(null, FIELD.CERTIFICATION),
    searchCountries: getAutocomplete.bind(null, FIELD.COUNTRY),
    searchEmployers: getAutocomplete.bind(null, FIELD.EMPLOYER),
    searchJDInstitutions: getAutocomplete.bind(null, FIELD.JD_SCHOOL),
    searchJobTitles: getAutocomplete.bind(null, FIELD.JOB_TITLE),

    searchLocations: getAutocompleteForLocations,

    searchMajor: getAutocomplete.bind(null, FIELD.MAJOR),
    searchMasterInstitutions: getAutocomplete.bind(null, FIELD.MASTERS_SCHOOL),
    searchMbaInstitutions: getAutocomplete.bind(null, FIELD.MBA_SCHOOL),
    searchMedicalInstitutions: getAutocomplete.bind(null, FIELD.MEDICAL_SCHOOL),
    searchPhdInstitutions: getAutocomplete.bind(null, FIELD.PHD_SCHOOL),
    searchProductBusiness: getAutocomplete.bind(null, FIELD.PRODUCT_BUSINESS),
    searchSkills: getAutocomplete.bind(null, FIELD.SKILL),
  };
}
