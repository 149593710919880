import { Controller, FieldValues } from 'react-hook-form';
import { FormHelperText, Typography } from '@mui/material';
import type { RadioInputProps, RadioOption } from './types';
import { css } from '@mui/material/styles';

const RadioInput = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  options,
  required = false,
}: RadioInputProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: { value: required, message: 'Required' } }}
      render={({ field, fieldState }) => (
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 10px 40px;
          `}
        >
          <Typography variant="body1">
            {label}
            {required ? <>&nbsp;*</> : null}
          </Typography>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              gap: 6px 20px;
            `}
          >
            {options.map((option: RadioOption) => (
              <Typography
                key={option.label}
                variant="body1"
                component="label"
                sx={{ display: 'flex', whiteSpace: 'nowrap' }}
              >
                <input
                  type="radio"
                  css={{ marginRight: 8 }}
                  {...field}
                  value={option.label}
                  checked={field.value === option.label}
                />
                {option.label}
              </Typography>
            ))}
          </div>
          {fieldState.error && (
            <FormHelperText error sx={{ margin: '4px 14px 0' }}>
              {fieldState.error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
};

export default RadioInput;
