import Form from '@/components/Survey/Form';
import { useSurveyContext } from '@/providers/SurveyProvider';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AutocompleteChipsInput, RadioInput, NumberInput } from '@/stories/components/Input';
import { Question } from '@/abstractions/question';
import { PopularAnswersApi } from '@/api/survey';
import { BUDGET_MANAGED_AFFINITY } from '@/constants/affinities';
import useAutocomplete from '@/hooks/useAutocomplete';

const MAX_SELECTED_SKILLS = 3;

function SkillsStep() {
  const { affinities, submitStep, formData, trackVirtualPageView, updateHeader } = useSurveyContext();
  const { searchSkills } = useAutocomplete();

  const form = useForm<SkillsFormData>({
    defaultValues: formData.skills,
  });

  const { control, handleSubmit, watch } = form;

  useEffect(() => {
    updateHeader({
      additionalInfo:
        'Did you know that certain skills can impact your pay? Your skill set distinguishes you from others in your role and can make you more competitive in the market.\n\nShare your top 3 skills to see how your expertise could affect your pay.',
      title: 'Your Skills',
      description: 'Share the top 3 skills that are critical to your job.',
    });

    trackVirtualPageView('/your-skills');
  }, []);

  const isSupervisor = watch('isSupervisor');
  const skills = watch('skills') || [];

  const onSubmit = handleSubmit((skillsData) =>
    submitStep({ rawFormData: skillsData, stepData: mapFormData(skillsData) })
  );

  const isCurrentJob = formData.job.jobSituation === 'Current Job';

  return (
    <Form onSubmit={onSubmit}>
      <AutocompleteChipsInput
        name="skills"
        control={control}
        currentValue={skills}
        label="Skills"
        selectionLimit={MAX_SELECTED_SKILLS}
        placeholder="Start by typing an answer"
        getOptions={searchSkills}
        getChipOptions={() =>
          PopularAnswersApi.getTopOptions('Skill', formData.job.jobTitle, formData.job.country, formData.job.location)
        }
      />
      <RadioInput
        name="isSupervisor"
        control={control}
        label={isCurrentJob ? 'Do you supervise employees?' : 'Will you supervise employees?'}
        options={[{ label: 'Yes' }, { label: 'No' }]}
      />
      {isSupervisor === 'Yes' && (
        <NumberInput
          name="quantitySupervised"
          control={control}
          label={
            isCurrentJob
              ? 'Number of people you manage both directly and indirectly'
              : 'Number of people you will manage both directly and indirectly'
          }
          min={0}
        />
      )}
      {isCurrentJob && affinities.includes(BUDGET_MANAGED_AFFINITY) && (
        <NumberInput name="budgetManaged" control={control} label="Budget managed per year" min={0} />
      )}
    </Form>
  );

  function mapFormData(data: SkillsFormData): Question[] {
    const mappedData: Question[] = [
      {
        questionId: 'ManagementRole',
        values: [data.isSupervisor],
        skipped: !data.isSupervisor,
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'Skills',
        values: data.skills,
        skipped: !data.skills.length,
        properties: { isDefaultAnswer: false },
      },
    ];

    if (isCurrentJob && affinities.includes(BUDGET_MANAGED_AFFINITY)) {
      mappedData.push({
        questionId: 'BudgetUnderManagement',
        values: [data.budgetManaged],
        skipped: !data.budgetManaged,
        properties: { isDefaultAnswer: false },
      });
    }

    if (data.isSupervisor === 'Yes') {
      mappedData.push({
        questionId: 'NumberSupervised',
        values: [data.quantitySupervised],
        skipped: !data.quantitySupervised,
        properties: { isDefaultAnswer: false },
      });
    }

    return mappedData;
  }
}

export interface SkillsFormData {
  budgetManaged?: number;
  isSupervisor?: 'Yes' | 'No';
  quantitySupervised?: number;
  skills: string[];
}

export default SkillsStep;
