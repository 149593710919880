import Form from '@/components/Survey/Form';
import { useSurveyContext } from '@/providers/SurveyProvider';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AutocompleteChipsInput, AutocompleteInput, DropdownInput } from '@/stories/components/Input';
import { isNullOrEmpty } from '@/util/isNullOrEmpty';
import { Question } from '@/abstractions/question';
import { PopularAnswersApi } from '@/api/survey';
import { DropdownOptions } from '@/stories/components/Input/types';
import NumberInputWithInapplicable from '@/stories/components/Input/NumberInputWithInapplicable';
import useAutocomplete from '@/hooks/useAutocomplete';

function EducationStep() {
  const {
    searchAssociateInstitutions,
    searchBachelorInstitutions,
    searchCertificateInstitutions,
    searchCertifications,
    searchJDInstitutions,
    searchMajor,
    searchMasterInstitutions,
    searchMedicalInstitutions,
    searchMbaInstitutions,
    searchPhdInstitutions,
  } = useAutocomplete();
  const minDegreeYear = 1900;
  const maxDegreeYear = new Date().getFullYear() + 6;

  // TODO: This may not be required - leaving it for now
  const showBachelorDegreeQuestionConditions = [
    BACHELORS_DEGREE,
    // MASTERS_DEGREE_NON_MBA,
    // MASTERS_DEGREE_MBA,
    // LAW_DEGREE,
    // DOCTORATE_DEGREE,
    // HEALTH_PROFESSIONAL_DOCTORATE,
  ];

  const { formData, submitStep, trackVirtualPageView, updateHeader } = useSurveyContext();

  const form = useForm<EducationFormData>({
    defaultValues: formData.education,
  });

  const { control, handleSubmit, watch } = form;

  useEffect(() => {
    updateHeader({
      additionalInfo:
        'Employers use degrees and certifications to ascertain the knowledge and expertise you bring into the role. Some certifications or degrees can be so desirable, or even mandatory, that they can directly unlock higher pay.',
      title: 'Your Education',
      description: 'Select highest degree completed.',
    });

    trackVirtualPageView('/your-education');
  }, []);

  const certifications = watch('certifications') || [];

  const onSubmit = handleSubmit((educationData) =>
    submitStep({
      rawFormData: educationData,
      stepData: mapFormData(educationData),
    })
  );

  return (
    <Form onSubmit={onSubmit}>
      <DropdownInput name="degree" control={control} label="Highest Degree Completed" options={degreeOptions} />

      {watch('degree') === CERTIFICATE_PROGRAM && (
        <>
          <AutocompleteInput
            name="certificateSchoolName"
            control={control}
            label={'Certificate School Name'}
            currentValue={watch('certificateSchoolName') ?? ''}
            getOptions={searchCertificateInstitutions}
            shouldUnregister
          />

          <AutocompleteChipsInput
            name="certificateMajor"
            control={control}
            label="Specialty/Focus"
            currentValue={watch('certificateMajor') ?? []}
            placeholder="Add all that apply"
            getOptions={searchMajor}
            shouldUnregister
          />

          <NumberInputWithInapplicable
            name="certificateYearGraduated"
            control={control}
            label={'Year of Graduation'}
            inapplicableLabel={'Did Not Graduate'}
            min={minDegreeYear}
            max={maxDegreeYear}
            shouldUnregister
          />
        </>
      )}

      {watch('degree') === ASSOCIATES_DEGREE && (
        <>
          <AutocompleteInput
            name="associateSchoolName"
            control={control}
            label={'Associate School Name'}
            currentValue={watch('associateSchoolName') ?? ''}
            getOptions={searchAssociateInstitutions}
            shouldUnregister
          />

          <AutocompleteChipsInput
            name="associateMajor"
            control={control}
            label="Major"
            currentValue={watch('associateMajor') ?? []}
            placeholder="Add all that apply"
            getOptions={searchMajor}
            shouldUnregister
          />

          <NumberInputWithInapplicable
            name="associateYearGraduated"
            control={control}
            label={'Year of Graduation'}
            inapplicableLabel={'Did Not Graduate'}
            min={minDegreeYear}
            max={maxDegreeYear}
            shouldUnregister
          />
        </>
      )}

      {watch('degree') === MASTERS_DEGREE_NON_MBA && (
        <>
          <AutocompleteInput
            name="masterSchoolName"
            control={control}
            label={'Master School Name'}
            currentValue={watch('masterSchoolName') ?? ''}
            getOptions={searchMasterInstitutions}
            shouldUnregister
          />

          <AutocompleteChipsInput
            name="masterMajor"
            control={control}
            label="Major"
            currentValue={watch('masterMajor') ?? []}
            placeholder="Add all that apply"
            getOptions={searchMajor}
            shouldUnregister
          />

          <NumberInputWithInapplicable
            name="masterYearGraduated"
            control={control}
            label={'Year of Graduation'}
            inapplicableLabel={'Did Not Graduate'}
            min={minDegreeYear}
            max={maxDegreeYear}
            shouldUnregister
          />
        </>
      )}

      {watch('degree') === MASTERS_DEGREE_MBA && (
        <>
          <AutocompleteInput
            name="mbaSchoolName"
            control={control}
            label={'Business School Name'}
            currentValue={watch('mbaSchoolName') ?? ''}
            getOptions={searchMbaInstitutions}
            shouldUnregister
          />

          <AutocompleteChipsInput
            name="mbaMajor"
            control={control}
            label="Specialty/Focus"
            currentValue={watch('mbaMajor') ?? []}
            placeholder="Add all that apply"
            getOptions={searchMajor}
            shouldUnregister
          />

          <NumberInputWithInapplicable
            name="mbaYearGraduated"
            control={control}
            label={'Year of Graduation'}
            inapplicableLabel={'Did Not Graduate'}
            min={minDegreeYear}
            max={maxDegreeYear}
            shouldUnregister
          />
        </>
      )}

      {watch('degree') === LAW_DEGREE && (
        <>
          <AutocompleteInput
            name="jdSchoolName"
            control={control}
            label={'Law School Name'}
            currentValue={watch('jdSchoolName') ?? ''}
            getOptions={searchJDInstitutions}
            shouldUnregister
          />

          <NumberInputWithInapplicable
            name="jdYearGraduated"
            control={control}
            label={'Year of Graduation'}
            inapplicableLabel={'Did Not Graduate'}
            min={minDegreeYear}
            max={maxDegreeYear}
            shouldUnregister
          />
        </>
      )}

      {watch('degree') === DOCTORATE_DEGREE && (
        <>
          <AutocompleteInput
            name="phdSchoolName"
            control={control}
            label={'Doctorate School Name'}
            currentValue={watch('phdSchoolName') ?? ''}
            getOptions={searchPhdInstitutions}
            shouldUnregister
          />

          <AutocompleteChipsInput
            name="phdMajor"
            control={control}
            label="Major"
            currentValue={watch('phdMajor') ?? []}
            placeholder="Add all that apply"
            getOptions={searchMajor}
            shouldUnregister
          />

          <NumberInputWithInapplicable
            name="phdYearGraduated"
            control={control}
            label={'Year of Graduation'}
            inapplicableLabel={'Did Not Graduate'}
            min={minDegreeYear}
            max={maxDegreeYear}
            shouldUnregister
          />
        </>
      )}

      {watch('degree') === HEALTH_PROFESSIONAL_DOCTORATE && (
        <>
          <AutocompleteInput
            name="mdSchoolName"
            control={control}
            label={'Health Professional School Name'}
            currentValue={watch('mdSchoolName') ?? ''}
            getOptions={searchMedicalInstitutions}
            shouldUnregister
          />

          <NumberInputWithInapplicable
            name="mdYearGraduated"
            control={control}
            label={'Year of Graduation'}
            inapplicableLabel={'Did Not Graduate'}
            min={minDegreeYear}
            max={maxDegreeYear}
            shouldUnregister
          />
        </>
      )}

      {showBachelorDegreeQuestionConditions.includes(watch('degree')) && (
        <>
          <AutocompleteInput
            name="bachelorSchoolName"
            control={control}
            label={'Bachelor School Name'}
            currentValue={watch('bachelorSchoolName') ?? ''}
            getOptions={searchBachelorInstitutions}
            shouldUnregister
          />

          <AutocompleteChipsInput
            name="bachelorMajor"
            control={control}
            label="Major"
            currentValue={watch('bachelorMajor') ?? []}
            placeholder="Add all that apply"
            getOptions={searchMajor}
            shouldUnregister
          />

          <NumberInputWithInapplicable
            name="bachelorYearGraduated"
            control={control}
            label={'Year of Graduation'}
            inapplicableLabel={'Did Not Graduate'}
            min={minDegreeYear}
            max={maxDegreeYear}
            shouldUnregister
          />
        </>
      )}

      <AutocompleteChipsInput
        name="certifications"
        control={control}
        currentValue={certifications}
        label="Certifications and Licenses"
        placeholder="Start by typing an answer"
        getOptions={searchCertifications}
        getChipOptions={() =>
          PopularAnswersApi.getTopOptions(
            'Certifications',
            formData.job.jobTitle,
            formData.job.country,
            formData.job.location
          )
        }
      />
    </Form>
  );

  function mapFormData(data: EducationFormData): Question[] {
    const mappedData = [
      {
        questionId: 'HighestDegreeEarnedEducationalLevel',
        values: [data.degree] as (string | undefined)[] | undefined,
        skipped: isNullOrEmpty(data.degree),
        properties: { isDefaultAnswer: false },
      },
      {
        questionId: 'Certifications',
        values: data.certifications,
        skipped: !data.certifications.length,
        properties: { isDefaultAnswer: false },
      },
    ];

    if (data.degree === CERTIFICATE_PROGRAM) {
      mappedData.push(
        {
          questionId: 'CertificateSchoolName',
          values: [data.certificateSchoolName],
          skipped: isNullOrEmpty(data.certificateSchoolName),
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'CertificateMajor',
          values: data.certificateMajor,
          skipped: !data.certificateMajor?.length,
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'CertificateYearGraduated',
          values: [data.certificateYearGraduated],
          skipped: isNullOrEmpty(data.certificateYearGraduated),
          properties: { isDefaultAnswer: false },
        }
      );
    }

    if (data.degree === ASSOCIATES_DEGREE) {
      mappedData.push(
        {
          questionId: 'AssociateSchoolName',
          values: [data.associateSchoolName],
          skipped: isNullOrEmpty(data.associateSchoolName),
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'AssociateMajor',
          values: data.associateMajor,
          skipped: !data.associateMajor?.length,
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'AssociateYearGraduated',
          values: [data.associateYearGraduated],
          skipped: isNullOrEmpty(data.associateYearGraduated),
          properties: { isDefaultAnswer: false },
        }
      );
    }

    if (showBachelorDegreeQuestionConditions.includes(data.degree)) {
      mappedData.push(
        {
          questionId: 'BachelorSchoolName',
          values: [data.bachelorSchoolName],
          skipped: isNullOrEmpty(data.bachelorSchoolName),
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'BachelorMajor',
          values: data.bachelorMajor,
          skipped: !data.bachelorMajor?.length,
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'BachelorYearGraduated',
          values: [data.bachelorYearGraduated],
          skipped: isNullOrEmpty(data.bachelorYearGraduated),
          properties: { isDefaultAnswer: false },
        }
      );
    }

    if (data.degree === MASTERS_DEGREE_NON_MBA) {
      mappedData.push(
        {
          questionId: 'MasterSchoolName',
          values: [data.masterSchoolName],
          skipped: isNullOrEmpty(data.masterSchoolName),
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'MasterMajor',
          values: data.masterMajor,
          skipped: !data.masterMajor?.length,
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'MasterYearGraduated',
          values: [data.masterYearGraduated],
          skipped: isNullOrEmpty(data.masterYearGraduated),
          properties: { isDefaultAnswer: false },
        }
      );
    }

    if (data.degree === MASTERS_DEGREE_MBA) {
      mappedData.push(
        {
          questionId: 'MbaSchoolName',
          values: [data.mbaSchoolName],
          skipped: isNullOrEmpty(data.mbaSchoolName),
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'MbaMajor',
          values: data.mbaMajor,
          skipped: !data.mbaMajor?.length,
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'MbaYearGraduated',
          values: [data.mbaYearGraduated],
          skipped: isNullOrEmpty(data.mbaYearGraduated),
          properties: { isDefaultAnswer: false },
        }
      );
    }

    if (data.degree === LAW_DEGREE) {
      mappedData.push(
        {
          questionId: 'JdSchoolName',
          values: [data.jdSchoolName],
          skipped: isNullOrEmpty(data.jdSchoolName),
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'JdYearGraduated',
          values: [data.jdYearGraduated],
          skipped: isNullOrEmpty(data.jdYearGraduated),
          properties: { isDefaultAnswer: false },
        }
      );
    }

    if (data.degree === DOCTORATE_DEGREE) {
      mappedData.push(
        {
          questionId: 'PhdSchoolName',
          values: [data.phdSchoolName],
          skipped: isNullOrEmpty(data.phdSchoolName),
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'PhdMajor',
          values: data.phdMajor,
          skipped: !data.phdMajor?.length,
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'PhdYearGraduated',
          values: [data.phdYearGraduated],
          skipped: isNullOrEmpty(data.phdYearGraduated),
          properties: { isDefaultAnswer: false },
        }
      );
    }

    if (data.degree === HEALTH_PROFESSIONAL_DOCTORATE) {
      mappedData.push(
        {
          questionId: 'MdSchoolName',
          values: [data.mdSchoolName],
          skipped: isNullOrEmpty(data.mdSchoolName),
          properties: { isDefaultAnswer: false },
        },
        {
          questionId: 'MdYearGraduated',
          values: [data.mdYearGraduated],
          skipped: isNullOrEmpty(data.mdYearGraduated),
          properties: { isDefaultAnswer: false },
        }
      );
    }

    return mappedData;
  }
}

const NO_DEGREE = 'No Degree';
const HIGH_SCHOOL_DIPLOMA = 'High School Diploma or GED';
const CERTIFICATE_PROGRAM = 'Non-Degree Certificate Program';
const ASSOCIATES_DEGREE = 'Associate Degree';
const BACHELORS_DEGREE = 'Bachelor’s Degree';
const MASTERS_DEGREE_NON_MBA = 'Master’s Degree (non-MBA)';
const MASTERS_DEGREE_MBA = 'Master’s of Business Administration (MBA)';
const LAW_DEGREE = 'Law Degree (JD, LLM)';
const DOCTORATE_DEGREE = 'Doctorate (PhD)';
const HEALTH_PROFESSIONAL_DOCTORATE = 'Health Professional Doctorate (MD, DMD, DVM, DPT, etc.)';

const degreeOptions: DropdownOptions[] = [
  NO_DEGREE,
  HIGH_SCHOOL_DIPLOMA,
  CERTIFICATE_PROGRAM,
  ASSOCIATES_DEGREE,
  BACHELORS_DEGREE,
  MASTERS_DEGREE_NON_MBA,
  MASTERS_DEGREE_MBA,
  LAW_DEGREE,
  DOCTORATE_DEGREE,
  HEALTH_PROFESSIONAL_DOCTORATE,
].map((label) => ({ label, value: label }));

export interface EducationFormData {
  degree: string;

  certificateSchoolName?: string;
  certificateMajor?: string[];
  certificateYearGraduated?: string;

  associateSchoolName?: string;
  associateMajor?: string[];
  associateYearGraduated?: string;

  bachelorSchoolName?: string;
  bachelorMajor?: string[];
  bachelorYearGraduated?: string;

  masterSchoolName?: string;
  masterMajor?: string[];
  masterYearGraduated?: string;

  mbaSchoolName?: string;
  mbaMajor?: string[];
  mbaYearGraduated?: string;

  jdSchoolName?: string;
  jdYearGraduated?: string;

  phdSchoolName?: string;
  phdMajor?: string[];
  phdYearGraduated?: string;

  mdSchoolName?: string;
  mdYearGraduated?: string;

  certifications: string[];
}

export default EducationStep;
