import { SurveyStepOptions } from '@/abstractions/surveyStepOptions';
import { DEMOGRAPHICS_STEP, JOB_STEP, PAY_STEP, SKILLS_STEP, EMPLOYER_STEP, EDUCATION_STEP } from './steps';

export const SURVEY_STEPS: SurveyStepOptions[] = [
  {
    enabled: true,
    id: JOB_STEP,
    navigationButtonText: 'Next: Skills',
  },
  {
    enabled: true,
    id: SKILLS_STEP,
    navigationButtonText: 'Next: Pay',
  },
  {
    enabled: true,
    id: PAY_STEP,
    navigationButtonText: 'Next: Education',
  },
  {
    enabled: true,
    id: EDUCATION_STEP,
    navigationButtonText: 'Next: Employer',
  },
  {
    enabled: true,
    id: EMPLOYER_STEP,
    navigationButtonText: 'Next: Demographics',
  },
  {
    enabled: true,
    id: DEMOGRAPHICS_STEP,
    navigationButtonText: 'Finish',
  },
];
